import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUserRetailerShoppingCarts } from "@/api/rest/checkoutApi";
import _ from "lodash";
import {
  ProductOperation,
  UNAUTHENTICATED_SEARCH_PRODUCTS,
  getEntityForLandingPage,
} from "../../../api";
import ReduxStore from "../../types";
import { AUTHENTICATED_SEARCH_PRODUCTS } from "../../../api/graphQl/authenticated/Product";
import { ThunkExtraArguments } from "@/redux/store";

export const getActiveCarts = createAsyncThunk(
  "/checkout/getActiveCarts",
  async () => {
    try {
      const { userRetailerShoppingCarts, products } =
        await getAllUserRetailerShoppingCarts();
      const sortedCarts = _.sortBy(userRetailerShoppingCarts, "retailerId");
      return {
        allCarts: sortedCarts,
        products,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getMainEntity = createAsyncThunk<
  any,
  { type?: string; id?: string },
  { extra: ThunkExtraArguments; state: ReduxStore }
>(
  "/mainEntity/getMainEntity",
  async ({ type, id }: { type?: string; id?: string }, thunkAPI) => {
    if (type === "brand") {
      const { brandEntity } = thunkAPI.getState();
      if (`${brandEntity?.id}` === `${id}` && brandEntity?.entity) {
        return new Promise((resolve, reject) => {
          resolve({ data: { ...brandEntity?.entity } } as any);
        });
      }
      return getEntityForLandingPage({
        entityId: id as any,
        entityType: type as any,
      });
    }
    if (type === "product") {
      const { userProfile } = thunkAPI.getState();
      const res = await getEntityForLandingPage({
        entityId: id as any,
        entityType: type as any,
      });

      let productEntity = null;
      if (userProfile?.userId) {
        const productEntityRes = await thunkAPI.extra.apolloClient.query({
          query: AUTHENTICATED_SEARCH_PRODUCTS,
          variables: {
            page: { start: 0, size: 1 },
            stacklineSkus: [id],
            computeFacets: false,
          },
        });
        if (
          productEntityRes?.data?.[ProductOperation.AuthenticatedSearch]
            ?.products?.length
        ) {
          productEntity =
            productEntityRes?.data?.[ProductOperation.AuthenticatedSearch]
              ?.products[0];
        }
      } else {
        const productEntityRes = await thunkAPI.extra.apolloClient.query({
          query: UNAUTHENTICATED_SEARCH_PRODUCTS,
          variables: {
            page: { start: 0, size: 1 },
            stacklineSkus: [id],
            computeFacets: false,
          },
        });
        if (
          productEntityRes?.data?.[ProductOperation.UnauthenticatedSearch]
            ?.products?.length
        ) {
          productEntity =
            productEntityRes?.data?.[ProductOperation.UnauthenticatedSearch]
              ?.products[0];
        }
      }

      // the score property is not present in the res.data, so fetch it from the productEntity and overwrite the res.data
      if (productEntity) {
        res.data = {
          ...res.data,
          ...productEntity,
        };
      }
      return res;
    }
    return getEntityForLandingPage({
      entityId: id as any,
      entityType: type as any,
    });
  }
);
