import React, { useState } from "react";
import { createOptimizedImageUrl } from "@/utils/StringUtils";

interface StoreFallbackImageProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  width: number;
  height: number;
  src?: string;
}

export const useFallbackImage = (
  src: string,
  width: number,
  height: number
) => {
  // The primary source is the optimized CDN URL
  const cdnUrl =
    src &&
    createOptimizedImageUrl({
      originalUrl: src,
      width,
      height,
      baseUrl: "https://media.brandclub.com",
    });

  // If that fails, we try the API that will generate the image
  const apiGenerateUrl =
    src &&
    createOptimizedImageUrl({
      originalUrl: src,
      width,
      height,
      baseUrl: "https://media-api.brandclub.com",
    });

  const [imgSrc, setImgSrc] = useState(cdnUrl);
  const [retryCount, setRetryCount] = useState(0);

  const onImageLoadError = () => {
    if (!cdnUrl && !apiGenerateUrl) {
      return;
    }
    if (retryCount === 0) {
      // first retry means the image is not on the CDN
      setRetryCount((count) => count + 1);
      // we'll try to generate the image using the API
      setImgSrc(apiGenerateUrl);
    } else if (retryCount === 1) {
      // second retry means the image is not on the CDN and the API failed
      setRetryCount((count) => count + 1);
      // we'll use the original image source as a fallback
      console.error(`Failed to get optimized image from CDN or API for ${src}`);
      setImgSrc(src);
    }
  };
  return {
    imgSrc,
    onImageLoadError,
  };
};

export const StoreFallbackImage: React.FC<StoreFallbackImageProps> = ({
  width,
  height,
  src = "",
  ...props
}) => {
  const { imgSrc, onImageLoadError } = useFallbackImage(src, width, height);
  return (
    <img src={imgSrc} onError={onImageLoadError} alt={props.alt} {...props} />
  );
};
