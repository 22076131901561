import { useState, useEffect, useCallback } from "react";
import { SearchResult } from "../../../../../api/types/searchResult";
import {
  getDailyDealProducts,
  getUnauthedDailyDealProducts,
} from "../../../../../api/graphQl/authenticated/DailyDeals";
import _get from "lodash/get";
import { ProductRewardSearchResult } from "../../../../../types/search";
import { useAppSelector } from "../../../../../redux/hooks";
import { uniqBy } from "lodash";
import { getBrandIds } from "@brandclub/common-ui";
import { getUserSignedInState } from "../../../../../Auth";
import { useApolloClient } from "@apollo/client";

export const useFetchDailyDeals = ({
  pageSize = 25,
  autoInitFetch = true,
}: {
  pageSize?: number;
  autoInitFetch?: boolean;
}) => {
  const apolloClient = useApolloClient();
  const [data, setData] = useState<ProductRewardSearchResult[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  // this is set to true initially to allow components to return null if there is no data and loading is false
  // and also wait for a component to be "in view" before fetching data. (see useInView usage in DailyDealsEmptyRewardPreview for example)
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const brandEntity = useAppSelector((state) => state.brandEntity?.entity);

  const fetchData = useCallback(
    async (pageNumber = 0) => {
      try {
        setError(null);
        const { signedIn } = await getUserSignedInState();

        const RESKEY = signedIn
          ? "DailyDealProducts"
          : "UnauthedDailyDealProducts";

        const res = await apolloClient.query<{
          // @ts-expect-error because it's dynamic
          [RESKEY]: SearchResult<ProductRewardSearchResult>;
        }>({
          query: signedIn ? getDailyDealProducts : getUnauthedDailyDealProducts,
          variables: {
            page: {
              start: pageNumber,
              size: pageSize,
            },
            brandIds: getBrandIds(brandEntity),
          },
          fetchPolicy: "network-only",
        });
        const newData = _get(res, ["data", RESKEY, "items"], []);
        const newHasMore = _get(res, ["data", RESKEY, "hasMore"], false);
        setData((d) => uniqBy([...d, ...newData], "stacklineSku"));
        setHasMore(newHasMore);
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
        setError("An error occurred while fetching daily deals");
      }
    },
    [pageSize, brandEntity, apolloClient]
  );

  const fetchInit = useCallback(async () => {
    try {
      setLoading(true);
      await fetchData(0);
    } catch {
    } finally {
      setPage(0);
      setLoading(false);
    }
  }, [fetchData]);

  const fetchMore = useCallback(async () => {
    try {
      setLoadingMore(true);
      await fetchData((page + 1) * pageSize);
    } finally {
      setPage((p) => p + 1);
      setLoadingMore(false);
    }
  }, [fetchData, page, pageSize]);

  useEffect(() => {
    if (autoInitFetch) {
      fetchInit();
    }
  }, [fetchInit, autoInitFetch]);

  return { data, loading, error, hasMore, fetchMore, loadingMore, fetchInit };
};
