import _orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";
import { useEffect, useState } from "react";
import { getRewardsForUser } from "../../../../../../api/graphQl/authenticated";
import { CustomerCashBackRewardForTable } from "../../../../../../types/misc";
import RewardList from "./RewardList";
import { useSiteContext } from "../../../../../SiteProvider";
import { useApolloClient } from "@apollo/client";

const FetchVariables = {
  expiringRewards: true,
  brandIds: undefined,
};

const ExpiringRewardList = () => {
  const apolloClient = useApolloClient();
  const { brandId } = useSiteContext();
  const [rows, setRows] = useState<CustomerCashBackRewardForTable[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchRewardsForUser = async (variables: any) => {
    try {
      setLoading(true);
      const { data } = await apolloClient.query<{
        CustomerCashBackReward: CustomerCashBackRewardForTable[];
      }>({
        query: getRewardsForUser,
        variables,
        fetchPolicy: "no-cache",
      });

      if (data?.CustomerCashBackReward) {
        // filter out rewards for products that don't have metadata associated with them to display
        setRows((existing) =>
          _orderBy(
            _uniqBy(
              [
                ...(existing || []),
                ...data.CustomerCashBackReward.filter(
                  (x: any) =>
                    !x.extendedAttributes.productId ||
                    (x.extendedAttributes.productId &&
                      x.extendedAttributes.additionalMetaData &&
                      x.extendedAttributes.additionalMetaData.stacklineSku)
                ),
              ],
              "rewardId"
            ),
            ["updatedTime"],
            ["desc"]
          )
        );
      }
    } catch (e) {
      console.error("error: ", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRewardsForUser({
      ...FetchVariables,
      brandIds: brandId ? [brandId] : undefined,
      page: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RewardList
      data={rows}
      listType="expiring"
      loading={loading}
      headerText="Expiring rewards"
    />
  );
};

export default ExpiringRewardList;
