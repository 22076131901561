import { CampaignType } from "@brandclub/common-ui";
import LongRewardCard from "../../../../../StoreComponents/StoreCard.tsx/LongRewardCard";
import {
  RewardUnlockStrategy,
  SurveyRewardCampaignForCarousel,
} from "../../../../../../types/misc";
import _get from "lodash/get";
import { getEntityImage } from "../../../../../../utils/StringUtils";

const SurveyCard = ({
  survey,
  border = false,
  className,
}: {
  survey: SurveyRewardCampaignForCarousel;
  border?: boolean;
  className?: string;
}) => {
  const rewardAmount = _get(
    survey,
    ["extendedAttributes", "reward", "reward", "amount"],
    0
  );
  const hasInstantReward =
    survey?.extendedAttributes?.rewardUnlockStrategy ===
    RewardUnlockStrategy.Instant;

  return (
    <LongRewardCard
      type="brand"
      title={survey.brandclub?.name || ""}
      subtitle={survey.survey?.description}
      image={
        survey?.brandclub?.imageUrl ||
        getEntityImage(`${survey.brandclub?.brandIds?.[0]}`, "brand")
      }
      rewardType={
        hasInstantReward
          ? CampaignType.DailyDealsRewardCampaign
          : CampaignType.SurveyRewardCampaign
      }
      rewardAmount={rewardAmount}
      linkTo={`/survey/${survey.campaignId}`}
      linkState={{
        brandId: survey.brandclub?.brandId,
        brandName: survey.brandclub?.name,
      }}
      border={border}
      className={className}
    ></LongRewardCard>
  );
};

export default SurveyCard;
