import { CardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import ReviewCard from "./components/ReviewCard";
import { useFetchReviews } from "./data";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import colors from "../../../../styles/colors";
import { useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";
import { useResponsiveData } from "../../../../../utils/hooks/useResponsiveData";
import { isWhite } from "../../../../../utils/StringUtils";

const ReviewPreview = ({
  brandIds,
  backgroundColor = colors.blueWhite,
  arrowColor = "blue",
  title,
  subtitle,
  className,
  pageSize = 5,
}: {
  brandIds?: number[];
  backgroundColor?: string;
  arrowColor?: "blue" | "grey" | null;
  title?: string;
  subtitle?: string | null;
  className?: string;
  pageSize?: number;
}) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);
  const { linkPrefix } = useLinkPrefix();
  const { data, loading, fetchInit } = useFetchReviews({
    pageSize,
    autoInitFetch: false,
    brandIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  const responsiveData = useResponsiveData(data);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper
      ref={ref}
      backgroundColor={backgroundColor}
      className={className}
    >
      <PreviewContainer className="preview-container--review">
        {loading && !data.length ? (
          <StoreLoadingSkeleton
            header
            bgColor={isWhite(backgroundColor) ? "white" : "grey"}
          />
        ) : (
          <>
            <PreviewHeader
              title={title || "Product reviews"}
              linkTo={linkPrefix + "/productreviews"}
              subtitle={
                subtitle === null
                  ? undefined
                  : subtitle || "Share feedback by writing reviews"
              }
              indicator={arrowColor === null ? undefined : arrowColor}
            />

            <CardGrid className="preview-card-grid">
              {responsiveData.slice(0, pageSize).map((d) => (
                <ReviewCard
                  product={d}
                  key={d.stacklineSku}
                  className="preview-review-card"
                />
              ))}
            </CardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default ReviewPreview;
