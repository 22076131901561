import { getContentForUnauthedUser } from "@/api/graphQl/authenticated/Content";
import { getContentForUserV2 } from "@/api/graphQl/authenticated/Content";
import { getUnauthedReviewProducts } from "@/api/graphQl/authenticated/Review";
import { getReviewProducts } from "@/api/graphQl/authenticated/Review";
import { getUnauthedSurveysForUser } from "@/api/graphQl/authenticated/Surveys";
import { getSurveysForUserV2 } from "@/api/graphQl/authenticated/Surveys";
import { useAppSelector } from "@/redux/hooks";
import { StoreBrandingType } from "@/types/misc";
import { useSignedIn } from "@/utils/hooks/useSignedIn";
import useStoreType from "@/utils/hooks/useStoreType";
import { useApolloClient } from "@apollo/client";

export const usePrefetchDTCOffers = () => {
  const storeType = useStoreType();
  const brandclubIds = useAppSelector(
    // for DTC brandAppConfig contains a stable brandclubIds, brandEntity may not be available at times due to clearing
    ({ appConfig }) => appConfig?.brandAppConfig?.brandclubIds
  );
  const brandIds = useAppSelector(
    ({ appConfig }) => appConfig?.brandAppConfig?.brandIds
  );
  const client = useApolloClient();
  const { signedIn } = useSignedIn();
  const isDtc = storeType === StoreBrandingType.CustomDTCStore;
  const surveyQuery = signedIn
    ? getSurveysForUserV2
    : getUnauthedSurveysForUser;
  const contentQuery = signedIn
    ? getContentForUserV2
    : getContentForUnauthedUser;
  const reviewQuery = signedIn ? getReviewProducts : getUnauthedReviewProducts;

  const prefetchSurveys = async () => {
    if (!isDtc) {
      // Using appConfig for brandclubIds works for DTC, but not for universal stores
      // (using brandEntity could cause race conditions during entity clearing)
      console.warn("Attempting to prefetch surveys for non-DTC store");
      return;
    }
    await client.query({
      query: surveyQuery,
      variables: {
        brandclubIds,
        page: {
          start: 0,
          size: 25,
        },
      },
      notifyOnNetworkStatusChange: false,
    });
  };

  const prefetchContent = async () => {
    if (!isDtc) {
      // Using appConfig for brandclubIds works for DTC, but not for universal stores
      // (using brandEntity could cause race conditions during entity clearing)
      console.warn("Attempting to prefetch content for non-DTC store");
      return;
    }
    await client.query({
      query: contentQuery,
      variables: {
        brandclubIds,
        page: {
          start: 0,
          size: 25,
        },
      },
      notifyOnNetworkStatusChange: false,
    });
  };

  const prefetchReviews = async () => {
    if (!isDtc) {
      // Using appConfig for brandclubIds works for DTC, but not for universal stores
      // (using brandEntity could cause race conditions during entity clearing)
      console.warn("Attempting to prefetch reviews for non-DTC store");
      return;
    }
    await client.query({
      query: reviewQuery,
      variables: {
        brandIds,
        page: {
          start: 0,
          size: 25,
        },
      },
      notifyOnNetworkStatusChange: false,
    });
  };

  const prefetchAll = async () => {
    await Promise.all([
      prefetchSurveys(),
      prefetchContent(),
      prefetchReviews(),
    ]);
  };

  return prefetchAll;
};
