import { useAppSelector } from "../../../../redux/hooks";
import { styled } from "@mui/material";

import BalanceSummary from "./Rewards/BalanceSummary";
import { Link } from "react-router-dom";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import AccountSummary from "./Account/AccountSummary";
import NewAccountSummary from "./Account/NewAccountSummary";
import PurchasePreview from "./Purchase/PurchasePreview";
import { MOBILE_SCREEN_SIZE } from "../../../AppNavigation/constants";
import { DashboardPreviewHeader } from "./DashboardPreviewHeader";
import { DashboardHeaderContainer } from "./DashboardHeaderContainer";
import useStoreType from "../../../../utils/hooks/useStoreType";
import { StoreBrandingType } from "../../../../types/misc";
import { DtcGetAppSectionProfileHome } from "@/components/Checkout/components/DtcGetApp/DtcGetAppComponent";

const DashboardContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  paddingBottom: 40,
  gap: 60,
  // alignItems: "flex-start",

  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: 40,
    alignItems: "center",
    "> div": {
      width: "100%",
    },
  },
});

const StyledDashboardHeaderContainer = styled(DashboardHeaderContainer)({
  marginBottom: 0,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    marginBottom: 0,
  },
  ".title": {
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 18,
    },
  },
});

export const DashboardSummaryHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  marginBottom: 40,
  ".title": {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 700,
  },
  ".subtitle": {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 500,
  },
}));
const DashboardHeader = () => {
  const storeType = useStoreType();
  const userprofile = useAppSelector((state) => state.userProfile);
  const userName = userprofile?.given_name;

  const createdAt = userprofile?.createdAt;

  const joinedYear = createdAt ? new Date(createdAt).getFullYear() : null;

  const title = userName ? `Hi, ${userName}.` : "Hi";
  const subtitle =
    storeType === StoreBrandingType.CustomDTCStore
      ? `Member since ${joinedYear || 2021}.`
      : "Welcome to your dashboard.";

  return (
    <StyledDashboardHeaderContainer>
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </StyledDashboardHeaderContainer>
  );
};

export type QuickLinkType = {
  label: string;
  subtitle: string;
  path: string;
};

const dtcQuickLinks = [
  {
    label: "Personal",
    subtitle: "Update your addresses and personal details.",
    path: "/profile",
  },
  {
    label: "Rewards",
    subtitle: "Check your reward balance and activity.",
    path: "/rewards/balance",
  },
];

const quickLinks = [
  {
    label: "Personal",
    subtitle: "Update your addresses and personal details.",
    path: "/dashboard/profile",
  },
  {
    label: "Purchase History",
    subtitle: "View or track your purchases with Brandclub.",
    path: "/dashboard/purchase",
  },

  {
    label: "Addresses",
    subtitle: "Add or edit your shipping addresses.",
    path: "/dashboard/address",
  },
  {
    label: "Wallet",
    subtitle: "Add or edit your payment methods.",
    path: "/dashboard/wallet",
  },
];

export const QuickLinksContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: 22,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: 10,
    gridTemplateColumns: "1fr",
  },
});

const QuickLinkContainer = styled("div")(({ theme }) => ({
  borderRadius: 7,
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "#f6f8fa",
  border: `1px solid #dcdddc`,
  color: theme.palette.primary.main,
  padding: "21px 15px 21px 25px",
  justifyContent: "space-between",
  ".title": {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 8,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },
  ".subtitle": {
    fontSize: 14,
    fontWeight: 500,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
    },
  },
}));
export const SingleQuickLink = ({
  quickLink,
}: {
  quickLink: QuickLinkType;
}) => {
  return (
    <Link to={quickLink.path} style={{ textDecoration: "none" }}>
      <QuickLinkContainer>
        <div className="link_info">
          <div className="title">{quickLink.label}</div>
          <div className="subtitle">{quickLink.subtitle}</div>
        </div>
        <KeyboardArrowRightRoundedIcon sx={{ fontSize: "24px" }} />
      </QuickLinkContainer>
    </Link>
  );
};

export const SingleExternalLink = ({
  quickLink,
}: {
  quickLink: QuickLinkType;
}) => {
  return (
    <a
      href={quickLink.path}
      rel="noreferrer"
      target="_blank"
      style={{ textDecoration: "none" }}
    >
      <QuickLinkContainer>
        <div className="link_info">
          <div className="title">{quickLink.label}</div>
          <div className="subtitle">{quickLink.subtitle}</div>
        </div>
        <KeyboardArrowRightRoundedIcon sx={{ fontSize: "24px" }} />
      </QuickLinkContainer>
    </a>
  );
};

const QuickLinks = () => {
  const storeType = useStoreType();

  const subtitle =
    storeType === StoreBrandingType.CustomDTCStore
      ? "Shortcuts to your member center."
      : "Update your account information";

  const quickLinksToUse =
    storeType === StoreBrandingType.CustomDTCStore ? dtcQuickLinks : quickLinks;

  return (
    <div>
      <DashboardPreviewHeader
        title={"Quick links"}
        subtitle={subtitle}
        marginBottom={25}
      />
      <QuickLinksContainer>
        {quickLinksToUse.map((link) => {
          return <SingleQuickLink quickLink={link} key={link.label} />;
        })}
      </QuickLinksContainer>
    </div>
  );
};

const Dashboard = ({ brand }: { brand?: boolean }) => {
  return (
    <div>
      <DashboardContainer>
        <DashboardHeader />
        <PurchasePreview />
        <BalanceSummary />
        {brand ? null : <QuickLinks />}
        <AccountSummary />
        <NewAccountSummary />
        <DtcGetAppSectionProfileHome />
      </DashboardContainer>
    </div>
  );
};

export default Dashboard;
