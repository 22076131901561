import { getCurrentReferralRewardCampaigns } from "../../api/graphQl/authenticated/ReferralReward";
import { setReferralReward } from "../reducers/referralReward";
import { AppDispatch, ThunkExtraArguments } from "../store";
import ReduxStore from "../types";

/* --------------------- referralReward ---------------------*/

export const loadReferralReward =
  () =>
  async (
    dispatch: AppDispatch,
    _getState: unknown,
    { apolloClient }: ThunkExtraArguments
  ): Promise<ReduxStore["referralReward"] | null> => {
    try {
      const { data } = await apolloClient.query({
        query: getCurrentReferralRewardCampaigns,
        fetchPolicy: "no-cache",
      });
      if (data && data.CurrentReferralRewardCampaigns) {
        const amountForInstallingUser =
          data?.CurrentReferralRewardCampaigns?.[0]?.extendedAttributes?.reward
            .rewardAmountForInstaller || null;
        const amountForReferringUser =
          data?.CurrentReferralRewardCampaigns?.[0]?.extendedAttributes?.reward
            .rewardAmountForReferrer || null;
        const referralReward = {
          amountForInstallingUser,
          amountForReferringUser,
        };
        dispatch(setReferralReward(referralReward));
        return referralReward;
      }
    } catch (e) {
      console.error("loadReferralReward error: ", e);
    }
    return null;
  };
