import { useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/redux/hooks";
import { getActiveCarts } from "@/redux/reducers/checkout/thunk";
import { updateItemsToUserRetailerShoppingCart } from "@/api/rest/checkoutApi";
import { ShoppingCartItem } from "./types";
import { AnalyticsTrackingEvent } from "@brandclub/common-ui";
import { useTrackActions } from "@/utils/hooks/useTracking";

export const useAutoAddToBag = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const processingRef = useRef(false);
  const [trackAction] = useTrackActions();

  useEffect(() => {
    const addItemToBag = async () => {
      // Check if we have any relevant parameters to process
      const retailerId = searchParams.get("retailerId");
      const retailerSku = searchParams.get("retailerSku");
      const stacklineSku = searchParams.get("stacklineSku");

      // If no relevant parameters exist, don't process anything
      if (!retailerId && !retailerSku && !stacklineSku) {
        return;
      }

      // Prevent multiple processing attempts
      if (processingRef.current) return;

      const quantity = parseInt(searchParams.get("quantity") || "1", 10);

      // Check if we have valid parameters based on the SKU type
      if (retailerSku && !retailerId) {
        console.error("retailerId is required when using retailerSku");
        return;
      }

      if (!retailerSku && !stacklineSku) {
        console.error("Either retailerSku or stacklineSku is required");
        return;
      }

      try {
        processingRef.current = true;

        // Prepare the item for the cart
        const item: ShoppingCartItem = {
          ...(retailerId ? { retailerId: parseInt(retailerId, 10) } : {}),
          quantity,
          ...(retailerSku ? { retailerSku } : {}),
          ...(stacklineSku ? { stacklineSku } : {}),
        } as ShoppingCartItem;

        trackAction(AnalyticsTrackingEvent.BC_USER_ADDED_TO_BAG_FROM_URL, {
          retailerId: retailerId ? parseInt(retailerId, 10) : 0, // API requires retailerId, use 0 for stacklineSku
          items: [item],
        });

        // Add item to cart
        await updateItemsToUserRetailerShoppingCart({
          retailerId: retailerId ? parseInt(retailerId, 10) : 0, // API requires retailerId, use 0 for stacklineSku
          items: [item],
        });

        // Remove the parameters from the URL before refreshing cart
        // This ensures we don't get into a loading state loop
        const newUrl = window.location.pathname;
        navigate(newUrl, { replace: true });

        // Refresh the cart contents
        await dispatch(getActiveCarts());
      } catch (error) {
        console.error("Failed to add item to bag:", error);
        // Remove parameters even on error to prevent stuck state
        const newUrl = window.location.pathname;
        navigate(newUrl, { replace: true });
      } finally {
        processingRef.current = false;
      }
    };

    addItemToBag();
  }, [searchParams, dispatch, navigate, trackAction]);
};
