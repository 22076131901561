import { AppDispatch, ThunkExtraArguments } from "../store";
import { setRetailers } from "../reducers/retailers";
import { getRetailersAuth } from "../../api/graphQl/authenticated";
import { getRetailers } from "../../api";
import { EligibilityStatus, RetailerWithSyncStatus } from "../types";

/* --------------------- retailers ---------------------*/

interface AuthRetailerResponse {
  Retailers: RetailerWithSyncStatus[];
}
interface UnauthRetailerResponse {
  RetailersForLandingPage: RetailerWithSyncStatus[];
}
export const loadRetailersAuth =
  () =>
  async (
    dispatch: AppDispatch,
    _getState: unknown,
    { apolloClient }: ThunkExtraArguments
  ): Promise<RetailerWithSyncStatus[] | null> => {
    try {
      const { data } = await apolloClient.query<AuthRetailerResponse>({
        query: getRetailersAuth,
        variables: { enableExperimentalRetailers: true },
        fetchPolicy: "no-cache",
      });
      if (data?.Retailers?.length > 0) {
        const serverSideEnabledRetailers = data.Retailers.map((retailer) => ({
          ...retailer,
          parentRetailerId: retailer?.parentRetailerId ?? retailer.retailerId,
        })).filter((retailer) => {
          return retailer.syncConfig.serverSide === EligibilityStatus.ENABLED;
        });
        dispatch(setRetailers(serverSideEnabledRetailers));
        return serverSideEnabledRetailers;
      }
    } catch (e) {
      console.error("loadRetailersAuth error: ", e);
    }
    return null;
  };

export const loadRetailersUnauth =
  () =>
  async (
    dispatch: AppDispatch,
    _getState: unknown,
    { apolloClient }: ThunkExtraArguments
  ): Promise<RetailerWithSyncStatus[] | null> => {
    try {
      const { data } = await apolloClient.query<UnauthRetailerResponse>({
        query: getRetailers,
        variables: { enableExperimentalRetailers: true },
        fetchPolicy: "cache-first",
      });
      const serverSideEnabledRetailers = data?.RetailersForLandingPage.filter(
        (retailer) => {
          return retailer.syncConfig.serverSide === EligibilityStatus.ENABLED;
        }
      );
      dispatch(setRetailers(serverSideEnabledRetailers));
      return serverSideEnabledRetailers;
    } catch (e) {
      console.error("loadRetailersUnauth error: ", e);
    }
    return null;
  };
