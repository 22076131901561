import { useAppSelector } from "@/redux/hooks";
import { DashboardPreviewHeader } from "../DashboardPreviewHeader";
import SurveyCardsPreview from "./SurveyCardsPreview";
import ContentPreview from "../../Pages/Content/preview";
import ReviewPreview from "../../Pages/Review/preview";
import { MOBILE_SCREEN_SIZE } from "@brandclub/common-ui";
import { styled } from "@mui/material/styles";
import DtcGetAppComponent from "@/components/Checkout/components/DtcGetApp/DtcGetAppComponent";

const StyledDtcGetAppComponent = styled(DtcGetAppComponent, {
  label: "DtcGetAppComponent-Offers",
})({
  marginBottom: 30,
  marginTop: 40,
});
const StyledContentPreview = styled(ContentPreview, {
  label: "ContentPreview-Offers",
})({
  paddingLeft: 0,
  paddingRight: 0,
  ".preview-container--content": {
    padding: "30px 0",
    ".img_container": {
      width: "100%",
      maxWidth: 136,
      maxHeight: 130,
    },
    ".details": {
      padding: "0px 0px 0px 20px",
    },
  },
});
const StyledReviewPreview = styled(ReviewPreview, {
  label: "ReviewPreview-Offers",
})({
  ".preview-container--review": {
    padding: "30px 0",
  },
  paddingLeft: 0,
  paddingRight: 0,
  ".preview-card-grid": {
    gridTemplateColumns: `repeat(auto-fill, minmax(200px, 220px))`,
    gridGap: 10,
    justifyContent: "start",
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(172px,  1fr))`,
    },
    ".image": {
      width: "70%",
      marginTop: 0,
      marginBottom: 0,
    },
    ".title": {
      height: "unset",
      marginTop: 20,
      WebkitLineClamp: 2,
      lineHeight: "normal",
    },
    ".preview-review-card": {
      paddingBottom: 22,
    },
  },
});

const Offers = () => {
  const brandclubIds = useAppSelector(
    // for DTC brandAppConfig contains a stable brandclubIds, brandEntity may not be available at times due to clearing
    ({ appConfig }) => appConfig?.brandAppConfig?.brandclubIds
  );
  const brandIds = useAppSelector(
    ({ appConfig }) => appConfig?.brandAppConfig?.brandIds
  );

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <DashboardPreviewHeader
          title={"Offers"}
          subtitle="Explore exclusive offers below"
          sx={{ ".subtitle": { fontSize: 18 } }}
          marginBottom={32}
        />
        <StyledDtcGetAppComponent type="offer" />
      </div>
      {brandclubIds && (
        <SurveyCardsPreview
          brandclubIds={brandclubIds}
          pageSize={25}
          title="Surveys"
          subtitle="Earn rewards for completing surveys"
        />
      )}
      {brandclubIds && (
        <StyledContentPreview
          brandclubIds={brandclubIds}
          backgroundColor="white"
          arrowColor={null}
          subtitle={"Earn rewards for viewing content"}
          pageSize={25}
          title="Content rewards"
        />
      )}
      <StyledReviewPreview
        brandIds={brandIds}
        backgroundColor="white"
        arrowColor={null}
        pageSize={25}
        title="Product reviews"
        subtitle={"Share feedback by writing reviews"}
      />
    </>
  );
};

export default Offers;
