import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateMFADetails } from "../../../../../api";
import { useAppSelector } from "../../../../../redux/hooks";
import { RetailerSyncSession } from "../../../../../types/misc";
import StoreInput from "../../../../StoreComponents/StoreInput";
import { AuthSignUpPageContainer } from "../../../Auth/AuthSignUpPageOutlet";
import AuthSolidButton from "../../../Auth/components/AuthSolidButton";
import {
  ERROR_MESSAGES,
  getErrorMessage,
} from "../../../../../utils/errors/errorUtils";
import { AnalyticsTrackingEvent } from "@brandclub/common-ui";
import { useTrackActions } from "../../../../../utils/hooks/useTracking";
import { styled } from "@mui/material/styles";

const EnterMFACode = ({
  retailerId: retailerIdFromProps,
  retailerSyncSession: retailerSyncSessionFromProps,
  onCompleteCallback,
  className,
}: {
  className?: string;
  retailerId?: number;
  retailerSyncSession?: RetailerSyncSession;
  onCompleteCallback?: () => Promise<void> | void;
}) => {
  const [trackAction] = useTrackActions();
  const { retailerId: retailerIdFromParams } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { retailerSyncSession: retailerSyncSessionFromState, navigateBackTo } =
    state || {};

  const retailers = useAppSelector(({ retailers }) => retailers);
  const [logoLoading, setLogoLoading] = useState(true);
  const [mfaCode, setMfaCode] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>();
  const [error, setError] = useState<string | undefined>(undefined);

  const retailer = useMemo(() => {
    if (retailers && (retailerIdFromParams || retailerIdFromProps)) {
      const retailerId = parseInt(
        `${retailerIdFromParams || retailerIdFromProps}`
      );
      const currentRetailer = retailers.find(
        (r) => r.retailerId === retailerId
      );
      if (currentRetailer) {
        return currentRetailer;
      }
    }
  }, [retailerIdFromParams, retailerIdFromProps, retailers]);

  useEffect(() => {
    trackAction(AnalyticsTrackingEvent.SERVER_SIDE_SYNC_MFA_ENTER_OPEN, {
      retailer,
      retailerSyncSessionFromState,
      retailerSyncSessionFromProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MFACodeTitle = styled("div")(() => ({
    "&&": { marginTop: "30px", marginBottom: "16px" },
  }));

  return (
    <AuthSignUpPageContainer className={className}>
      {!retailer ||
        !(retailerSyncSessionFromState || retailerSyncSessionFromProps) || (
          <div className="auth_container">
            <img
              style={{ contentVisibility: logoLoading ? "hidden" : "visible" }}
              onLoad={() => setLogoLoading(false)}
              onLoadStart={() => setLogoLoading(true)}
              className="retailer_logo"
              alt="r_logo"
              src={retailer?.logo}
            ></img>
            <MFACodeTitle className="page_title">
              Verify your account
            </MFACodeTitle>
            <div className="page_subtitle">
              {retailerSyncSessionFromProps?.eventSequence?.data
                ?.displayText ? (
                <div>
                  {
                    retailerSyncSessionFromProps?.eventSequence?.data
                      ?.displayText
                  }
                </div>
              ) : (
                <div>Please enter your verification code here</div>
              )}
            </div>
            <StoreInput
              label="Verification code"
              inputStyle={{ width: "100%" }}
              style={{ marginTop: 20, marginBottom: 20 }}
              value={mfaCode}
              autoFocus
              onChange={(e) => {
                setError(undefined);
                setMfaCode(e.target.value);
              }}
              errorMsg={error}
            ></StoreInput>
            <AuthSolidButton
              disabled={!mfaCode || isSubmitting}
              onClick={async () => {
                setIsSubmitting(true);
                try {
                  const response = await updateMFADetails({
                    syncId: (
                      retailerSyncSessionFromState ||
                      retailerSyncSessionFromProps
                    ).syncId,
                    reqId: (
                      retailerSyncSessionFromState ||
                      retailerSyncSessionFromProps
                    ).eventSequence?.id,
                    otpValue: mfaCode,
                    eventType: "enter",
                  });
                  if (response?.errorMessage) {
                    throw new Error(response.errorMessage);
                  }
                  if (navigateBackTo) {
                    navigate(navigateBackTo);
                  }
                  if (onCompleteCallback) {
                    await onCompleteCallback();
                  }
                } catch (e) {
                  if (e instanceof Error) {
                    setError(getErrorMessage({ error: e }));
                  } else {
                    setError(ERROR_MESSAGES.default[0]);
                  }
                } finally {
                  setIsSubmitting(false);
                }
              }}
            >
              Continue
            </AuthSolidButton>
            <div className="resend" style={{ textAlign: "center" }}>
              If you didn’t receive a code via email or text, please check your
              authenticator app for two factor authorization.
            </div>
          </div>
        )}
    </AuthSignUpPageContainer>
  );
};

export default EnterMFACode;
