import { ArrowCircleRightIcon } from "@brandclub/common-ui";
import { styled } from "@mui/material/styles";
import { ComponentProps, ReactNode } from "react";
import { Link } from "react-router-dom";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeaderContainer = styled("div", { label: "HeaderContainer" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: 10,
  ".title": {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 20,
    },

    ".arrow": {
      marginLeft: 15,
      width: 22,
      height: 22,
      objectFit: "contain",
    },
  },
  ".subtitle": {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },
}));

export const PreviewHeader = ({
  linkTo,
  title,
  subtitle,
  indicator,
  logoImg,
  marginBottom,
  className,
}: {
  linkTo?: string;
  title: ReactNode;
  subtitle?: ReactNode;
  indicator?: "blue" | "grey";
  logoImg?: string;
  marginBottom?: number;
  className?: string;
}) => {
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const defaultMarginBottom = isMobileView ? 30 : 37;
  marginBottom = marginBottom ?? defaultMarginBottom;
  const renderContent = () => {
    return (
      <>
        {logoImg ? (
          <img
            alt="logo"
            src={logoImg}
            style={{
              height: 80,
              width: 80,
              objectFit: "contain",
              borderRadius: 999,
              marginRight: 25,
            }}
          ></img>
        ) : null}
        <HeaderContainer className={className}>
          <div className="title">
            {title}{" "}
            {indicator ? (
              <ArrowCircleRightIcon
                className="arrow"
                backgroundColor={indicator === "blue" ? "primary" : "#f6f7f8"}
                sx={(theme) => ({
                  color:
                    indicator === "blue"
                      ? "#ffffff"
                      : theme.palette.primary.main,
                })}
              />
            ) : null}
          </div>
          {subtitle ? <div className="subtitle">{subtitle}</div> : null}
        </HeaderContainer>
      </>
    );
  };
  if (!linkTo) {
    return (
      <div
        style={{
          textDecoration: "none",
          display: "flex",
          flexDirection: "row",
          marginBottom: marginBottom,
          alignItems: "center",
        }}
      >
        {renderContent()}
      </div>
    );
  }
  return (
    <Link
      to={linkTo ? linkTo : "#"}
      style={{
        textDecoration: "none",
        display: "flex",
        flexDirection: "row",
        marginBottom: marginBottom,
        alignItems: "center",
        width: "fit-content",
      }}
    >
      {renderContent()}
    </Link>
  );
};

interface LandingHeaderProps extends ComponentProps<typeof HeaderContainer> {
  title: string;
  subtitle?: ReactNode;
  marginBottom?: number;
}
export const LandingHeader = ({
  title,
  subtitle,
  marginBottom,
  style,
  ...props
}: LandingHeaderProps) => {
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const defaultMarginBottom = isMobileView ? 30 : 37;
  marginBottom = marginBottom ?? defaultMarginBottom;
  return (
    <HeaderContainer style={{ marginBottom, ...style }} {...props}>
      <div className="title">{title}</div>
      {subtitle ? <div className="subtitle">{subtitle}</div> : null}
    </HeaderContainer>
  );
};
