import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ProductOperation,
  UNAUTHENTICATED_SEARCH_PRODUCTS,
  getEntityForLandingPage,
} from "../../../api";
import ReduxStore, { BrandEntity } from "../../types";
import { AUTHENTICATED_SEARCH_PRODUCTS } from "../../../api/graphQl/authenticated/Product";
import _merge from "lodash/merge";
import { CheckoutEligibility, MatchedProduct } from "@brandclub/common-ui";
import { ApolloClient } from "@apollo/client";
import { ThunkExtraArguments } from "@/redux/store";

interface ProductCheckoutEligibilityMatchedProduct extends MatchedProduct {
  stacklineSku: string;
}
export interface ProductCheckoutEligibilityData {
  checkoutEligibility: CheckoutEligibility;
  matchedProducts: ProductCheckoutEligibilityMatchedProduct[];
}

const fetchProductEntity = async (
  apolloClient: ApolloClient<object>,
  stacklineSku: string | undefined,
  isUserLoggedIn: boolean
) => {
  if (!stacklineSku) {
    return null;
  }

  const variables = {
    page: { start: 0, size: 1 },
    stacklineSkus: [stacklineSku],
    computeFacets: false,
  };

  if (isUserLoggedIn) {
    const productEntityRes = await apolloClient.query({
      query: AUTHENTICATED_SEARCH_PRODUCTS,
      variables,
    });
    return productEntityRes?.data?.[ProductOperation.AuthenticatedSearch]
      ?.products?.[0];
  }
  const productEntityRes = await apolloClient.query({
    query: UNAUTHENTICATED_SEARCH_PRODUCTS,
    variables,
  });
  return productEntityRes?.data?.[ProductOperation.UnauthenticatedSearch]
    ?.products?.[0];
};

const updateProductCheckoutEligibility = <
  PRODUCT extends { stacklineSku: string }
>(
  product: PRODUCT,
  matchedProducts?: ProductCheckoutEligibilityMatchedProduct[]
) => {
  const matchedProduct = matchedProducts?.find(
    ({ stacklineSku }) => stacklineSku === product.stacklineSku
  );
  if (matchedProduct) {
    return {
      ...product,
      checkoutEligibility: matchedProduct.checkoutEligibility,
    };
  }
  return product;
};

export const mergeProductData = (
  res: any,
  productEntity?: any,
  productCheckoutEligibility?: ProductCheckoutEligibilityData | null
) => {
  // the score property is not present in the res.data, so fetch it from the productEntity and overwrite the res.data
  const data = _merge({}, res.data, productEntity ?? {});
  if (productCheckoutEligibility) {
    data.checkoutEligibility = productCheckoutEligibility.checkoutEligibility;
    // checkoutEligibility is not present in the matchedProducts, so we need to update it
    const matchedProducts = data.matchedProducts?.map((product: any) =>
      updateProductCheckoutEligibility(
        product,
        productCheckoutEligibility.matchedProducts
      )
    );
    data.matchedProducts = matchedProducts;
  }
  return data;
};

export const getMainEntity = createAsyncThunk<
  { data: any },
  { type: "brand" | "product" | "category" | "subCategory"; id?: string },
  { extra: ThunkExtraArguments; state: ReduxStore }
>("/mainEntity/getMainEntity", async ({ type, id }, thunkAPI) => {
  try {
    if (type === "product") {
      const { userProfile } = thunkAPI.getState();
      const isUserLoggedIn = !!userProfile?.userId;
      const [res, productEntity] = await Promise.all([
        getEntityForLandingPage({
          entityId: id as any,
          entityType: type,
        }),
        fetchProductEntity(thunkAPI.extra.apolloClient, id, isUserLoggedIn),
      ]);

      const mergedData = mergeProductData(res, productEntity);
      return {
        data: mergedData,
      };
    }
    if (type === "brand") {
      const { brandEntity } = thunkAPI.getState() as ReduxStore;
      if (`${brandEntity?.id}` === `${id}` && brandEntity?.entity) {
        return new Promise<{ data: BrandEntity["entity"] }>(
          (resolve, reject) => {
            resolve({ data: { ...brandEntity?.entity } });
          }
        );
      }
    }
    const entityResponse = await getEntityForLandingPage({
      entityId: id as any,
      entityType: type,
    });
    return { data: entityResponse.data };
  } catch (error) {
    console.error("getMainEntity error", error);
    throw new Error("Error fetching main entity");
  }
});
