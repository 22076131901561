import { DashboardPreviewHeader } from "@/components/pages/AppPages/Dashboard/DashboardPreviewHeader";
import { useSendStatus } from "@/components/pages/AppPages/GetApp/useSendStatus";
import { sendText } from "@/components/pages/AppPages/GetApp/utils";
import { useLocationTrackingParams } from "@/components/providers/LocationTrackingProvider";
import { StoreError } from "@/components/StoreComponents/StoreError";
import { useAppSelector } from "@/redux/hooks";
import { ERROR_MESSAGES } from "@/utils/errors/errorUtils";
import { validatePhone } from "@/utils/StringUtils";
import {
  AnalyticsService,
  AnalyticsTrackingEvent,
  formatPhoneNumber,
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "@brandclub/common-ui";
import { styled, useTheme } from "@mui/material";
import React, { useState } from "react";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  boxSizing: "border-box",

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    flexDirection: "column-reverse",
    gap: "40px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    flexDirection: "column-reverse",
    gap: "32px",
    paddingTop: "37.5px",
    paddingBottom: "37.5px",
    width: "100vw",
    alignSelf: "center",
  },

  ".text-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      alignItems: "center",
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {},
    ".title": {
      fontWeight: 700,
      color: theme.palette.primary.main,
      textAlign: "left",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        textAlign: "center",
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginBottom: "10px",
        fontSize: "18px",
      },
    },
    ".description": {
      fontWeight: 500,
      color: theme.palette.primary.main,
      textAlign: "left",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        textAlign: "center",
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginBottom: "24px",
        fontSize: "14px",
        maxWidth: "330px",
      },
    },
    ".cta-button": {
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      cursor: "pointer",
      borderRadius: "100px",
      backgroundColor: theme.palette.primary.main,
      fontWeight: 700,
      color: theme.palette.primary.contrastText,
      span: {
        display: "block",
      },
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: "14px",
        paddingTop: "8px",
        paddingBottom: "8px",
        minWidth: "300px",
        minHeight: "36px",
      },
    },
    ".cta-button-input": {
      width: 370,
      height: 60,
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: "7.9px 7.9px 7.9px 19.5px",
      borderRadius: "100px",
      backgroundColor: "#fff",
      border: `2px solid ${theme.palette.primary.main}`,
      gap: "10px",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: 300,
        height: 45,
        padding: "0 4px 0 13.5px",
      },
      ".cta-input-phone": {
        width: "100%",
        border: "none",
        outline: "none",
        borderRadius: "100px",
        color: theme.palette.primary.main,
      },
      ".cta-input-send": {
        borderRadius: "100px",
        border: "none",
        padding: "11px 21px",
        fontWeight: 700,
        whiteSpace: "nowrap",
        fontSize: "16px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          padding: "9px 20px",
          fontSize: "14px",
        },
      },
      ".prefix": {
        borderRadius: "100px",
        fontWeight: 700,
        color: theme.palette.primary.main,
      },
    },
  },
  ".image-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    img: {
      objectFit: "contain",
    },

    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      img: {
        maxHeight: "258px !important",
      },
    },
  },
}));

const StyledMapping = {
  membership: {
    paddingTop: "78.8px",
    paddingBottom: "78.8px",
    gap: "10%",
    justifyContent: "center",
    ".title": {
      fontSize: "32px",
      marginBottom: "16px",
    },
    ".description": {
      fontSize: "20px",
      marginBottom: "40px",
      maxWidth: "400px",
    },
    ".cta-button": {
      padding: "12px 36px",
      fontSize: "16px",
    },
    ".image-container": {
      img: {
        maxHeight: "458px",
      },
    },
  },
  receipt: {
    marginTop: "86px",
    width: "100%",
    boxSizing: "border-box",
    paddingTop: "43.5px",
    paddingBottom: "43.5px",
    paddingLeft: "71px",
    paddingRight: "71px",
    gap: "20px",
    justifyContent: "space-between",
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      boxSizing: "content-box",
    },
    ".title": {
      fontSize: "20px",
      marginBottom: "16px",
    },
    ".description": {
      fontSize: "16px",
      marginBottom: "24px",
      maxWidth: "320px",
    },
    ".cta-button": {
      padding: "12px 36px",
      fontSize: "16px",
    },
    ".image-container": {
      img: {
        maxHeight: "281px",
      },
    },
  },
  offer: {
    paddingTop: "43.5px",
    paddingBottom: "43.5px",
    paddingLeft: "71px",
    paddingRight: "71px",
    gap: "20px",
    justifyContent: "space-between",
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      placeSelf: "center",
    },
    ".title": {
      fontSize: "32px",
      marginBottom: "16px",
    },
    ".description": {
      fontSize: "20px",
      marginBottom: "40px",
      maxWidth: "400px",
    },
    ".cta-button": {
      padding: "12px 36px",
      fontSize: "16px",
    },
    ".image-container": {
      img: {
        maxHeight: "369px",
      },
    },
  },
};

const DtcGetAppComponent = ({
  type = "offer",
  className,
}: {
    type?: keyof typeof StyledMapping;
    className?: string;
}) => {
  const [inputPhoneNumber, setInputPhoneNumber] = useState("");
  const theme = useTheme();
  const { error, setError, sendStatus, setSendStatus } = useSendStatus({
    onStatusReset() {
      setInputPhoneNumber("");
    },
  });
  const userProfile = useAppSelector((state) => state?.userProfile);
  const appImage = theme?.dtcGetApp?.appImage;
  const backgroundColor = theme?.dtcGetApp?.backgroundColor;
  const enabled = theme?.dtcGetApp?.enabled;

  const [queryParams] = useLocationTrackingParams();
  const phoneNumber = userProfile?.phoneNumber;
  const customStyle = StyledMapping[type];

  const getValidPhoneNumber = () => {
    if (phoneNumber) {
      return phoneNumber.replace(/\D/g, "");
    }

    if (validatePhone(inputPhoneNumber)) {
      return `+1${inputPhoneNumber}`;
    }
    return undefined;
  };

  const handleSendText = async () => {
    try {
      const validPhoneNumber = getValidPhoneNumber();
      if (!validPhoneNumber) {
        setError("Please enter a valid phone number.");
        return;
      }

      AnalyticsService?.track(AnalyticsTrackingEvent.GET_APP, {
        phoneNumber: validPhoneNumber,
        brandName: brandName,
        userProfile: userProfile,
      });
      setError("");
      setSendStatus("sending");
      await sendText({ phoneNumber: validPhoneNumber, queryParams });
      setSendStatus("sent");
    } catch (e) {
      console.error("Error sending text invite", e);
      setError(ERROR_MESSAGES.default[0]);
      setSendStatus("notSend");
    }
  };
  const domainConfig = useAppSelector(
    (state) => state?.appConfig?.domainConfig
  );
  const brandName = domainConfig?.brandName;

  if (!enabled || !brandName || !appImage) return null;

  return (
    <Container
      sx={{ backgroundColor: backgroundColor || "#f6f8fa80", ...customStyle }}
      className={className}
    >
      <div className="text-container">
        <div className="title">Get the {brandName} app</div>
        <div className="description">
          Engage with us anywhere and access exclusive member benefits in the
          app.
        </div>
        {phoneNumber ? (
          <div className="cta-button" role="button" onClick={handleSendText}>
            <span
              style={{
                height: 0,
                whiteSpace: "nowrap",
                visibility: "hidden",
                margin: 0,
                padding: 0,
              }}
            >
              {`Text link to ${formatPhoneNumber(phoneNumber)}`}
            </span>
            <span
              style={{
                whiteSpace: "nowrap",
                width: "100%",
                textAlign: "center",
              }}
            >
              {sendStatus === "sending"
                ? "Sending..."
                : sendStatus === "sent"
                ? "Sent"
                : `Text link to ${formatPhoneNumber(phoneNumber)}`}
            </span>
          </div>
        ) : (
          <div className="cta-button-input">
            <div className="prefix">+1</div>
            <input
              value={inputPhoneNumber}
              onChange={(e) => setInputPhoneNumber(e.target.value)}
              className="cta-input-phone"
              type="text"
              placeholder="Phone number"
            />
            <button
              className="cta-input-send"
              onClick={handleSendText}
              style={{
                display: "block",
              }}
            >
              <span
                style={{
                  height: 0,
                  whiteSpace: "nowrap",
                  visibility: "hidden",
                  margin: 0,
                  padding: 0,
                  display: "block",
                }}
              >
                Text me a link
              </span>
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                {sendStatus === "sending"
                  ? "Sending..."
                  : sendStatus === "sent"
                  ? "Sent"
                  : "Text me a link"}
              </span>
            </button>
          </div>
        )}
        <StoreError
          errorMessage={error}
          sx={{
            marginTop: 2.5,
            width: "max-content",
          }}
        />
      </div>
      <div className="image-container">
        <img src={appImage} alt="app" />
      </div>
    </Container>
  );
};

const StyledContainer = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    marginBottom: "-240px",
  },
});
export const DtcGetAppSectionProfileHome = () => {
  const theme = useTheme();

  const enabled = theme?.dtcGetApp?.enabled;
  const appImage = theme?.dtcGetApp?.appImage;
  const domainConfig = useAppSelector(
    (state) => state?.appConfig?.domainConfig
  );
  const brandName = domainConfig?.brandName;

  if (!enabled || !brandName || !appImage) return null;

  return (
    <StyledContainer>
      <DashboardPreviewHeader
        title={"Access app exclusive offers"}
        subtitle="Download the app to unlock additional features"
      />
      <DtcGetAppComponent type="offer" />
    </StyledContainer>
  );
};

export default DtcGetAppComponent;
