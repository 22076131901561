import { getAuthorizationHeader } from "@/Auth";
import { SolidButton } from "@/components/StoreComponents/StoreButton";
import StoreInput from "@/components/StoreComponents/StoreInput";
import { useAppSelector } from "@/redux/hooks";
import axios from "@/utils/axios";
import { styled } from "@mui/material";
import {
  Elements,
  ExpressCheckoutElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  loadStripe,
  StripeExpressCheckoutElementConfirmEvent,
} from "@stripe/stripe-js";
import { useState } from "react";
import Stripe from "stripe";

const Container = styled("div")(({ theme }) => ({
  maxWidth: 764,
  contentVisibility: "auto",
  containIntrinsicSize: "auto 500px",

  ".reward_section": {
    contentVisibility: "auto",
    containIntrinsicSize: "auto 500px",
    marginBottom: 20,
    ".sec_title": {
      fontSize: 18,
      fontWeight: 700,
      color: theme.palette.primary.main,
      marginBottom: 20,
    },
    ".sec_data": {
      display: "flex",
      flexDirection: "column",
      gap: 20,
    },
    ".error_message": {
      color: theme.palette.error.main,
    },
    ".input_container": {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      maxWidth: 400,
    },
  },
}));

const stripePromise = loadStripe(
  "pk_test_51LnQyZJrInA7nkEZROUUcHyEsSs2CP3eWlIzOzIk6eMyGIKF8vsjZ4cGFjxrjZas6FlwTs2F9YvxrGsbdwojbxRO00IBijaZzD"
);

const StripeDebugging: React.FC = () => {
  const [clientSecret, setClientSecret] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const { paymentIntent, paymentIntentClientSecret, redirectStatus } =
    useConfirmationParams();

  const createPaymentIntent = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        "/stripe/create-payment-intent",
        {
          amount: parseInt(amount) * 100, // Convert dollars to cents
        },
        {
          headers: {
            ...(await getAuthorizationHeader()),
          },
        }
      );

      return data as Stripe.PaymentIntent;
    } catch (error) {
      console.error("Error creating payment intent:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartPayment = async () => {
    if (!amount || isNaN(parseInt(amount)) || parseInt(amount) <= 0) {
      return;
    }
    const data = await createPaymentIntent();
    if (data.client_secret) {
      setClientSecret(data.client_secret);
    }
  };

  const renderContent = () => {
    if (paymentIntent && paymentIntentClientSecret && redirectStatus) {
      return (
        <div>
          <h3>Payment Success Debug Info</h3>
          <div>
            <p>
              <strong>Payment Intent:</strong> {paymentIntent}
            </p>
            <p>
              <strong>Client Secret:</strong> {paymentIntentClientSecret}
            </p>
            <p>
              <strong>Redirect Status:</strong> {redirectStatus}
            </p>
          </div>
          <SolidButton
            onClick={() => {
              setClientSecret("");
              setAmount("");
              // need to clear the url params
              window.history.replaceState({}, "", window.location.pathname);
              window.location.reload();
            }}
            style={{ marginTop: "20px" }}
          >
            Start New Payment
          </SolidButton>
        </div>
      );
    }

    if (!clientSecret) {
      return (
        <div>
          <p>
            This section allows you to initiate a payment process using Stripe.
            Enter the amount in USD that you wish to charge, and click the
            "Start Payment" button. Upon clicking, a payment intent will be
            created, and if successful, the client secret will be retrieved for
            further processing.
          </p>
          <p>
            Note: this uses Stripe's sandbox/test mode and no payments will be
            charged
          </p>
          <div className="input_container">
            <StoreInput
              label="Amount (USD)"
              value={amount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAmount(e.target.value)
              }
              type="number"
              placeholder="Enter amount"
            />
            <SolidButton
              onClick={handleStartPayment}
              disabled={!amount || isNaN(parseInt(amount)) || isLoading}
            >
              {isLoading ? "Creating payment..." : "Start Payment"}
            </SolidButton>
          </div>
        </div>
      );
    }

    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret,
        }}
      >
        <CheckoutInner clientSecret={clientSecret} />
      </Elements>
    );
  };

  return (
    <Container>
      <div className="reward_section">
        <div className="sec_title">Stripe Debugging</div>
        <div className="sec_data">{renderContent()}</div>
      </div>
    </Container>
  );
};

const useConfirmationParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const paymentIntent = searchParams.get("payment_intent");
  const paymentIntentClientSecret = searchParams.get(
    "payment_intent_client_secret"
  );
  const redirectStatus = searchParams.get("redirect_status");

  return {
    paymentIntent,
    paymentIntentClientSecret,
    redirectStatus,
  };
};

const CheckoutInner = ({ clientSecret }: { clientSecret: string }) => {
  const elements = useElements();
  const stripe = useStripe();

  const user = useAppSelector((state) => state.userProfile);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePaymentSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/dashboard/debugging/stripe`,
        },
      });

      if (error) {
        setErrorMessage(error.message ?? "An error occurred");
      }
    } catch (e) {
      setErrorMessage("An unexpected error occurred");
    } finally {
      setIsProcessing(false);
    }
  };

  const onConfirm = async (event: StripeExpressCheckoutElementConfirmEvent) => {
    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError?.message ?? "");
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/dashboard/debugging/stripe`,
      },
    });

    if (error) {
      if (error.message) {
        setErrorMessage(error.message);
      }
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      {errorMessage && <div className="error_message">{errorMessage}</div>}

      <div style={{ marginBottom: "20px" }}>
        <h3>Express Checkout</h3>
        <ExpressCheckoutElement
          options={{
            emailRequired: true,
            phoneNumberRequired: true,
            shippingAddressRequired: true,
            billingAddressRequired: true,
            wallets: {
              applePay: "auto",
              googlePay: "auto",
            },
          }}
          onConfirm={onConfirm}
        />
      </div>

      <div>
        <h3>Card Payment</h3>
        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: {
                name: `${user.given_name} ${user.family_name}`,
                email: user.email,
                phone: user.phoneNumber,
              },
            },
          }}
        />
        <SolidButton
          onClick={handlePaymentSubmit}
          disabled={isProcessing}
          style={{ marginTop: "20px" }}
        >
          {isProcessing ? "Processing..." : "Pay with Card"}
        </SolidButton>
      </div>
    </>
  );
};

export default StripeDebugging;
