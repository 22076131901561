import { InMemoryCache } from "@apollo/client";
import {
  ContentRewardCampaignForCarousel,
  SurveyRewardCampaignForCarousel,
} from "../types/misc";
import { ProductRewardSearchResult } from "@/types/search";

interface PageResponse<T> {
  hasMore: boolean;
  nextPage: {
    start: number;
    size: number;
  } | null;
  items: T[];
}

export type SurveyPageResponse = PageResponse<SurveyRewardCampaignForCarousel>;
export type ContentPageResponse =
  PageResponse<ContentRewardCampaignForCarousel>;
export type ReviewPageResponse = PageResponse<ProductRewardSearchResult>;

const createCampaignMergeFunction = <T, K extends keyof T = keyof T>(
  /**
   * A function that returns a unique key for the item
   */
  getUniqueKey: (item: T) => T[K]
) => {
  return (
    existing: PageResponse<T> | undefined,
    incoming: PageResponse<T> | undefined
  ) => {
    // No merging needed in these cases:
    if (!existing || !incoming) {
      return incoming || existing;
    }

    try {
      const existingItems = existing.items || [];
      const incomingItems = incoming.items || [];

      const existingItemsMap = new Map(
        existingItems.map((item) => [getUniqueKey(item), item])
      );

      // Process incoming items to either update existing or add new
      incomingItems.forEach((item) => {
        // Always update with latest data from server
        existingItemsMap.set(getUniqueKey(item), item);
      });
      const mergedItems: T[] = [];
      const processedIds = new Set<T[K]>();

      // First add all existing items that are still in the map (may have updated values)
      existingItems.forEach((item) => {
        const updatedItem = existingItemsMap.get(getUniqueKey(item));
        if (updatedItem) {
          mergedItems.push(updatedItem);
          processedIds.add(getUniqueKey(item));
        }
      });

      incomingItems.forEach((item) => {
        if (!processedIds.has(getUniqueKey(item))) {
          const finalItem = existingItemsMap.get(getUniqueKey(item));
          if (finalItem) {
            mergedItems.push(finalItem);
            processedIds.add(getUniqueKey(item));
          }
        }
      });

      return {
        ...incoming,
        items: mergedItems,
      };
    } catch (err) {
      return incoming;
    }
  };
};

export const surveyMergeFunction =
  createCampaignMergeFunction<SurveyRewardCampaignForCarousel>(
    (item) => item.campaignId
  );

export const contentMergeFunction =
  createCampaignMergeFunction<ContentRewardCampaignForCarousel>(
    (item) => item.campaignId
  );

export const reviewMergeFunction =
  createCampaignMergeFunction<ProductRewardSearchResult>(
    (item) => item.stacklineSku
  );

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        SurveysForUserV2: {
          keyArgs: ["brandclubIds", "includedCampaignIds"],
          merge: surveyMergeFunction,
        },
        UnauthedSurveysForUser: {
          keyArgs: ["brandclubIds", "includedCampaignIds"],
          merge: surveyMergeFunction,
        },
        ContentForUserV2: {
          keyArgs: ["brandclubIds", "includedCampaignIds"],
          merge: contentMergeFunction,
        },
        UnauthedContentForUser: {
          keyArgs: ["brandclubIds", "includedCampaignIds"],
          merge: contentMergeFunction,
        },
        ReviewProducts: {
          keyArgs: ["brandIds"],
          merge: reviewMergeFunction,
        },
        UnauthedReviewProducts: {
          keyArgs: ["brandIds"],
          merge: reviewMergeFunction,
        },
      },
    },
  },
});
