import { isParentProduct } from "./StringUtils";

export const getDefaultProductImage = (stacklineSku: string) => {
  return `https://media.brandclub.com/p/428x428/${
    isParentProduct(stacklineSku) ? "parent-products/" : ""
  }${stacklineSku}.jpg`;
};

export const getFallbackProductImage = (stacklineSku: string) => {
  return `https://media-api.brandclub.com/p/428x428/${
    isParentProduct(stacklineSku) ? "parent-products/" : ""
  }${stacklineSku}.jpg`;
};

export const getDefaultBrandclubImage = (brandId: number) => {
  return `https://media.brandclub.com/b/428x428/${brandId}.jpg`;
};

export const getFallbackBrandclubImage = (brandId: number) => {
  return `https://media-api.brandclub.com/b/428x428/${brandId}.jpg`;
};

export const buildFallBackImageUrl = (mainImageUrl: string) => {
  return mainImageUrl.replace(
    /media\.brandclub\.com/,
    "media-api.brandclub.com"
  );
};

export const convertS3UrlToCDN = (url: string) => {
  return url.replace(
    "brandclub-images.s3.us-west-2.amazonaws.com",
    "media.brandclub.com"
  );
};
