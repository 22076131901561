import { styled } from "@mui/material";
import { motion } from "framer-motion";
import {
  desktopHeaderHeight,
  mobileHeaderHeight,
} from "../../../../StoreComponents/StoreSinglePageOutlet";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";

const FlowContainer = styled(motion.div, {
  label: "FlowContainer",
})({
  width: "100%",
  minHeight: `calc(100dvh - ${desktopHeaderHeight}px)`,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingBottom: 30,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    justifyContent: "flex-start",
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    justifyContent: "flex-start",
    minHeight: `calc(100dvh - ${mobileHeaderHeight}px)`,
  },
});

export default FlowContainer;
