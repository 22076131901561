import {
  BRANDCLUB_BRAND_ID,
  CampaignType,
  FallbackImage,
  RewardPillWithText,
  brandclub_colors,
  changeAmazonImageUrlResolution,
  getAppConsistentParams,
  getProductUrl,
  getPurchaseRewardAmountForProduct,
} from "@brandclub/common-ui";
import { Grid, Skeleton, styled } from "@mui/material";
import { formatMoney } from "@stackline/ui";
import _get from "lodash/get";
import {
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import {
  ProductOperation,
  SearchRequestBody,
  UNAUTHENTICATED_SEARCH_PRODUCTS,
} from "../../api";
import { useAppSelector } from "../../redux/hooks";
import { PageContext } from "../pages/AppPages/BrandStore/types";
import { useSiteContext } from "../SiteProvider";
import { StyledBox } from "./CategoriesDropdown";
import { MOBILE_SCREEN_SIZE, TABLET_SCREEN_SIZE } from "./constants";
import SearchInput from "./SearchBar";
import {
  getCombinedSearchTerms,
  useSearchAutoComplete,
} from "./useSearchAutoComplete";
import { useApolloClient } from "@apollo/client";

export type KeywordSuggestionsType = {
  text: string;
  category?: {
    categoryId: number;
    categoryName: string;
    categoryType: string;
    categoryWeight: number;
    settingType: string;
  };
};

// const SCROLLBAR_WIDTH = 20;
const containerWidth = 900;

const SearchBarContainer = styled("div")({
  //   position: 'absolute',
  display: "flex",
  justifyContent: "center",
  flex: 1,
  transition: "all 0.5s ease",

  marginBottom: 15,
});

const SearchBar = styled("div")({
  display: "flex",
  alignItems: "center",
  backgroundColor: brandclub_colors.blueWhite,
  borderRadius: 300,
  paddingLeft: 18,
  paddingRight: 14,

  width: "100%",
  transition: "all 0.5s ease",
  zIndex: 1202,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    backgroundColor: brandclub_colors.white,
    border: `1px solid #dcdddc`,
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    backgroundColor: brandclub_colors.white,
    border: `1px solid #dcdddc`,
  },
});

const KeywordBox = styled("div")({
  width: 207,
  minWidth: 207,
  marginRight: 30,
});
const ProductBox = styled("div")({
  flex: 1,
});
const Header = styled("div")(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.primary.main,
  paddingTop: 5,
  paddingBottom: 5,
}));

const Keyword = styled("div")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  cursor: "pointer",
  color: theme.palette.primary.main,
  paddingTop: 5,
  paddingBottom: 5,
}));

const SearchDropdownContainer = styled("div")({
  display: "flex",
  paddingBottom: 45,
  flexDirection: "row",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    // marginTop: 6,
    flexDirection: "column",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    flexDirection: "column",
  },
});

const NavProductsWrapper = styled("div")(({ theme }) => ({
  gap: "45px 30px",
  marginTop: "20px",
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  a: {
    color: theme.palette.primary.main,
    textDecoration: "none !important",
    "&:hover": {
      textDecoration: "none !important",
    },
  },
}));
const NavProductContainer = styled("div")(({ theme }) => ({
  height: 100,
  width: 286,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    width: "unset",
  },
  display: "flex",
  ".image": {
    width: 100,
    height: 100,
    aspectRatio: "1/1",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: `0 15px 0 0`,
    img: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
  },
  ".price": {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
  },
  ".title": {
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 500,
    margin: "3px 0 7px 0",
    width: "100%",
    overflow: "hidden",
    letterSpacing: 0.36,
    WebkitLineClamp: 2,
    lineHeight: 1.5,
    height: `3em`, // line height * 2
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
  },
}));

const Divider = styled("div")({
  borderTop: "1px solid #f6f8fa",
  width: "100%",
  margin: "30px 0",
  display: "none",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    display: "block",
  },
});

const NavProductCard = ({
  product,
  onClose,
}: {
  product: any;
  onClose: () => void;
}) => {
  const {
    hasActiveInstantReward,
    retailPrice,
    title,
    totalRewardAmount,
    stacklineSku,
  } = product;
  const urlSearchParams = getAppConsistentParams(window.location.search);
  const purchaseRewardAmount = getPurchaseRewardAmountForProduct(product);
  const rewardPercent =
    !!retailPrice && !!purchaseRewardAmount
      ? (purchaseRewardAmount * 100) / retailPrice
      : 0;
  const targetUrl = getProductUrl({ title, stacklineSku, urlSearchParams });

  const img = changeAmazonImageUrlResolution(
    _get(product, ["imageUrls", 0]),
    300
  );
  return (
    <Link to={targetUrl} onClick={onClose}>
      <NavProductContainer>
        <div className="image">
          <FallbackImage
            src={img}
            alt={title}
            fallbackImage={img.replace(
              "media.brandclub.com",
              "media-api.brandclub.com"
            )}
          />
        </div>
        <Grid display={"flex"} flexDirection={"column"} padding={"4px 0"}>
          <div className="price">{formatMoney(retailPrice)}</div>
          <div className="title">{title}</div>
          <div>
            <RewardPillWithText
              rewardAmount={totalRewardAmount ?? 0}
              rewardCampaignType={
                hasActiveInstantReward
                  ? CampaignType.DailyDealsRewardCampaign
                  : CampaignType.PurchaseRewardCampaign
              }
              rewardPercent={rewardPercent}
              rewardPercentText="reward"
              containerStyle={undefined}
              baseFontSize={11}
            />
          </div>
        </Grid>
      </NavProductContainer>
    </Link>
  );
};

const NavProducts = ({
  products,
  loading,
  onClose,
}: {
  products: any;
  loading: boolean;
  onClose: () => void;
}) => {
  return (
    <NavProductsWrapper>
      {loading ? (
        <>
          {Array.from(Array(4).keys()).map((idx) => (
            <Skeleton
              key={idx}
              variant="rectangular"
              width={"100%"}
              height={100}
              style={{ borderRadius: 6 }}
              sx={{ bgcolor: "#f6f8fa" }}
            />
          ))}
        </>
      ) : (
        <>
          {products.map((p: any) => {
            return (
              <NavProductCard
                key={p.retailerSku}
                product={p}
                onClose={onClose}
              />
            );
          })}
        </>
      )}
    </NavProductsWrapper>
  );
};

const SearchDropdown = ({
  searchInput,
  onClose,
  onSubmitSearch,
  setSearchInput,
  filterDialogOpen,
  setFilterDialogOpen,
  filterCount,
  setFilterCount,
  pageContext,
  persistentQueryParams,
}: {
  searchInput: string;
  onClose: () => void;
  onSubmitSearch: (searchInput?: string) => void;
  setSearchInput: (input: string) => void;
  filterDialogOpen: boolean;
  setFilterDialogOpen: (open: boolean) => void;
  filterCount: number;
  setFilterCount: (count: number) => void;
  pageContext: PageContext;
  persistentQueryParams?: string;
}) => {
  const apolloClient = useApolloClient();
  const aborterRef = useRef(new AbortController());
  const { abTestingTreatments } = useSiteContext();
  const {
    fetchSearchTermsWithSearchHistory,
    fetchSearchAutoComplete,
    searchHistory,
  } = useSearchAutoComplete();
  const [popularKeywords, setPopularKeywords] = useState<
    KeywordSuggestionsType[]
  >([]);
  const [keywordsSuggestions, setKeywordsSuggestions] = useState<
    KeywordSuggestionsType[]
  >([]);
  const [loadingPopularKeywords, setLoadingPopularKeywords] =
    useState<boolean>(false);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  // only specify the brandId if we're on a custom brand page
  const brandId = useAppSelector((state) =>
    state.appConfig?.domainConfig.brandId === BRANDCLUB_BRAND_ID
      ? undefined
      : state.appConfig?.domainConfig.brandId
  );
  // only specify the brandEntity if we're on a custom brand page
  const brandEntity = useAppSelector(({ appConfig, brandEntity }) =>
    appConfig?.domainConfig.brandId === brandEntity?.entity?.brandId
      ? brandEntity?.entity
      : undefined
  );
  const deferredInput = useDeferredValue(searchInput);
  const [products, setProducts] = useState<any[]>([]);

  useEffect(() => {
    const fetchPopularKeywords = async () => {
      try {
        setLoadingPopularKeywords(true);
        const args = {
          searchText: "",
          searchScoreByCategoryId: brandEntity?.searchScoreByCategory,
          brandId,
          preventAbort: true,
        };
        const suggestions = await (brandId
          ? fetchSearchAutoComplete(args)
          : fetchSearchTermsWithSearchHistory(args));

        setPopularKeywords(suggestions.map((t) => ({ text: t.searchTerm })));
      } catch (error) {
        console.error("error on search suggestion", error);
      } finally {
        setLoadingPopularKeywords(false);
      }
    };
    fetchPopularKeywords();
  }, [
    fetchSearchTermsWithSearchHistory,
    brandId,
    brandEntity?.searchScoreByCategory,
    fetchSearchAutoComplete,
  ]);

  const fetchSuggestProducts = useCallback(
    async (searchTerm: string) => {
      try {
        setLoadingProducts(true);
        const searchRequest: SearchRequestBody = {
          brandIds: brandId != null ? [brandId] : [],
          sort: [],
          treatment: {
            search: abTestingTreatments.searchImprovementTreatment,
          },
        };
        if (searchTerm ?? deferredInput) {
          searchRequest["titleInclude"] = [searchTerm ?? deferredInput];
        }
        const { data } = await apolloClient.query({
          query: UNAUTHENTICATED_SEARCH_PRODUCTS,
          variables: {
            ...searchRequest,
            page: {
              start: 0,
              size: 4,
            },
            computeFacets: false,
          },
          context: {
            fetchOptions: {
              signal: aborterRef.current.signal,
            },
          },
        });
        setProducts(
          data?.[ProductOperation.UnauthenticatedSearch]?.products ?? []
        );
        setLoadingProducts(false);
      } catch (error) {
        console.error("error on search products on nav", error);
      }
    },
    [
      abTestingTreatments.searchImprovementTreatment,
      deferredInput,
      brandId,
      apolloClient,
    ]
  );

  const fetchSuggestKeywords = useCallback(async () => {
    try {
      const suggestions = await fetchSearchAutoComplete({
        searchText: deferredInput,
        searchScoreByCategoryId: brandEntity?.searchScoreByCategory,
        brandId,
      });
      if (brandId) {
        setKeywordsSuggestions(
          suggestions.map((t) => ({ text: t.searchTerm }))
        );
        fetchSuggestProducts(suggestions[0]?.searchTerm);
        return;
      }
      const filteredSuggestions = getCombinedSearchTerms({
        searchHistory,
        suggestedKeywords: suggestions,
        searchText: deferredInput,
      }).map((t) => ({ text: t.searchTerm }));
      setKeywordsSuggestions(filteredSuggestions);
      fetchSuggestProducts(filteredSuggestions[0]?.text);
    } catch (error) {
      console.error("error on search suggestion", error);
    }
  }, [
    deferredInput,
    fetchSuggestProducts,
    fetchSearchAutoComplete,
    searchHistory,
    brandId,
    brandEntity?.searchScoreByCategory,
  ]);

  useEffect(() => {
    if (loadingPopularKeywords) {
      // ensure the popluar keywords are loaded before fetching the suggested keywords to prevent the popular keywords request from being aborted
      return;
    }
    const controller = new AbortController();
    aborterRef.current = controller;
    if (!deferredInput) {
      setProducts([]);
      setKeywordsSuggestions([]);
      setLoadingProducts(false);
    } else if (deferredInput && deferredInput.length > 0) {
      fetchSuggestKeywords();
    }
    return () => controller.abort();
  }, [deferredInput, fetchSuggestKeywords, loadingPopularKeywords]);

  const showSuggestedKeywords =
    keywordsSuggestions.length || deferredInput.length;

  return (
    <StyledBox style={{ maxWidth: containerWidth }}>
      <SearchBarContainer>
        <SearchBar>
          <SearchInput
            searchIcon
            onSubmit={onSubmitSearch}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            persistentQueryParams={persistentQueryParams}
            pageContext={pageContext}
            filterDialogOpen={filterDialogOpen}
            setFilterDialogOpen={setFilterDialogOpen}
            filterCount={filterCount}
            setFilterCount={setFilterCount}
            onClose={onClose}
          />
        </SearchBar>
      </SearchBarContainer>
      <SearchDropdownContainer>
        <KeywordBox>
          <Header>
            {showSuggestedKeywords ? "Suggested searches" : "Popular searches"}
          </Header>
          {showSuggestedKeywords
            ? keywordsSuggestions.map((keyword, index) => {
                return (
                  <Keyword
                    key={index}
                    onClick={() => {
                      onSubmitSearch(keyword.text);
                    }}
                  >
                    {keyword.text}
                  </Keyword>
                );
              })
            : popularKeywords.map((keyword, index) => {
                return (
                  <Keyword
                    key={index}
                    onClick={() => {
                      onSubmitSearch(keyword.text);
                    }}
                  >
                    {keyword.text}
                  </Keyword>
                );
              })}
        </KeywordBox>
        {products.length || loadingProducts ? (
          <>
            <Divider />
            <ProductBox>
              <Header>
                {searchInput ? "Suggested products" : "Popular products"}
              </Header>
              <NavProducts
                loading={loadingProducts}
                products={products}
                onClose={onClose}
              />
            </ProductBox>
          </>
        ) : (
          <div></div>
        )}
      </SearchDropdownContainer>
    </StyledBox>
  );
};

export default SearchDropdown;
