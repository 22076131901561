import { useCallback } from "react";
import {
  getContentForUnauthedUser,
  getContentForUserV2,
} from "../../../../../api/graphQl/authenticated/Content";
import { useSignedIn } from "@/utils/hooks/useSignedIn";
import { NetworkStatus, useQuery } from "@apollo/client";
import { ContentPageResponse } from "@/redux/apolloCache";

interface ContentForUserResponse {
  ContentForUserV2: ContentPageResponse;
}

interface UnauthedContentForUserResponse {
  UnauthedContentForUser: ContentPageResponse;
}

// Use a type that combines both possible responses for type safety
type QueryResponse = Partial<
  ContentForUserResponse & UnauthedContentForUserResponse
>;

// Define variables type
interface ContentQueryVariables {
  brandclubIds?: string[] | null;
  excludedBrandclubIds?: string[];
  includedCampaignIds?: string[];
  page: {
    start: number;
    size: number;
  };
}

interface UseFetchContentProps {
  /**
   * Whether to fetch the content automatically when the component mounts
   */
  autoInitFetch?: boolean;
  /**
   * The brandclub ids to include in the query
   */
  brandclubIds?: string[];
  /**
   * The campaign ids to include in the query
   */
  includedCampaignIds?: string[];
  /**
   * The size of the page to fetch
   */
  pageSize?: number;
}

export const useFetchContent = ({
  autoInitFetch = true,
  brandclubIds,
  includedCampaignIds,
  pageSize = 25,
}: UseFetchContentProps) => {
  const { signedIn } = useSignedIn();
  const query = signedIn ? getContentForUserV2 : getContentForUnauthedUser;

  const {
    data: queryData,
    loading,
    error,
    fetchMore,
    networkStatus,
    refetch,
  } = useQuery<QueryResponse, ContentQueryVariables>(query, {
    variables: {
      brandclubIds: brandclubIds || null,
      excludedBrandclubIds: ["BRANDCLUB"],
      includedCampaignIds,
      page: {
        start: 0,
        size: pageSize,
      },
    },
    skip: !autoInitFetch,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const loadingMore = loading && networkStatus === NetworkStatus.fetchMore;
  const fetchInit = useCallback(() => {
    return refetch();
  }, [refetch]);

  const handleFetchMore = useCallback(() => {
    if (!queryData) return Promise.resolve();

    // Get the correct data based on the signed-in state
    const responseData = signedIn
      ? queryData.ContentForUserV2
      : queryData.UnauthedContentForUser;

    if (!responseData || !responseData.hasMore) {
      return Promise.resolve();
    }

    // Let Apollo's cache handle merging based on the typePolicies
    return fetchMore({
      variables: {
        page: {
          start: responseData.nextPage?.start || 0,
          size: pageSize,
        },
      },
      // No updateQuery needed - Apollo cache will handle the merge
    });
  }, [fetchMore, queryData, signedIn, pageSize]);

  // Get data based on signed-in state
  const responseData = queryData
    ? signedIn
      ? queryData.ContentForUserV2
      : queryData.UnauthedContentForUser
    : null;

  const data = responseData?.items || [];
  const hasMore = responseData?.hasMore || false;

  return {
    data,
    loading,
    error: error ? error.message : null,
    hasMore,
    fetchMore: handleFetchMore,
    fetchInit,
    loadingMore,
  };
};
