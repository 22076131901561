import FlowContainer from "../../../pages/AppPages/Pages/components/FlowContainer";

import { useAppSelector } from "../../../../redux/hooks";
import { useCheckoutContext } from "../../CheckoutMachine";
import { styled } from "@mui/material";
import ConnectRetailer from "../../../pages/AppPages/Sync/Connect";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { useSelector } from "@xstate/react";
import { getPurchaseRewardAmountForProductV2 } from "@brandclub/common-ui";

export interface FormState {
  username: string;
  password: string;
}

const StyledConnectRetailer = styled(ConnectRetailer)(({ theme }) => ({
  ".auth_container": {
    [`@media screen and (min-width: ${TABLET_SCREEN_SIZE}px)`]: {
      paddingTop: 175,
    },
    ".reward_summary": {
      textAlign: "center",
      ...(theme.dtc && {
        margin: "unset",
        width: "100%",
        maxWidth: "unset !important",
        marginTop: "20px",
      }),
    },
  },
}));

const ConnectContainer = styled("div")(({ theme }) => ({
  maxWidth: "580px",
  width: "100%",
  marginBottom: "68px",
  ".close": {
    cursor: "pointer",
    position: "absolute",
    top: 20,
    right: 20,
    width: 20,
    height: 20,
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".close_img": {
      width: 14,
      height: 14,
    },
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      top: 10,
      right: 10,
      width: 20,
      height: 20,
    },
  },
}));

const CheckoutConnect = () => {
  const { actorRef, send: checkoutSend } = useCheckoutContext();
  const activeCheckoutRetailerId = useSelector(
    actorRef,
    (snapshot) => snapshot.context.activeCheckoutRetailerId
  );

  const { products } = useAppSelector((state) => state.checkout);

  const allOptimisticCarts = useAppSelector(
    (state) => state.checkout.optimisticAllCarts
  );
  const allCarts = useAppSelector((state) => state.checkout.allCarts);

  const checkoutRetailerConnectionState = useSelector(
    actorRef,
    (snapshot) => snapshot.context.checkoutRetailerConnectionState
  );
  const retailers = useAppSelector(({ retailers }) => retailers);
  const retailerToConnect = retailers.find(
    (retailer) => retailer.retailerId === activeCheckoutRetailerId
  );

  const currentCarts = (allOptimisticCarts || allCarts)?.find(
    (cart) => cart.retailerId === activeCheckoutRetailerId
  );

  const currentProducts =
    products &&
    currentCarts?.items?.map((item) => {
      const product = products[item.stacklineSku];
      return {
        ...product,
        quantity: item.quantity,
      };
    });

  const rewardAmount = currentProducts?.reduce((acc, product) => {
    const { amount } = getPurchaseRewardAmountForProductV2(product);
    return acc + amount;
  }, 0);
  const setLoginInfo = (values: FormState) => {
    checkoutSend({
      type: "ON_LOGIN_INFO_SUBMIT",
      data: {
        connectionInfo: {
          retailerId: retailerToConnect?.retailerId as number,
          credential: {
            username: values.username,
            password: values.password,
            connectionStatus: "notConnected",
          },
        },
      },
    });
  };

  return (
    <FlowContainer>
      <ConnectContainer>
        <StyledConnectRetailer
          retailerId={retailerToConnect?.retailerId}
          connectOverwrite={(username, password) => {
            setLoginInfo({ username, password });
          }}
          additionalRewardAmount={rewardAmount}
          errorMsg={checkoutRetailerConnectionState?.errorMessage}
        />
      </ConnectContainer>
    </FlowContainer>
  );
};

export default CheckoutConnect;
