import { gql } from "@apollo/client";

export const getSurveysForUserV2 = gql`
  query getSurveysForUserV2(
    $brandclubIds: [String!]
    $includedCampaignIds: [String!]
    $page: PageFilter
  ) {
    SurveysForUserV2(
      brandclubIds: $brandclubIds
      includedCampaignIds: $includedCampaignIds
      page: $page
    ) {
      hasMore
      nextPage
      items {
        campaignId
        extendedAttributes
        brandclub {
          imageUrl
          name
          brandclubId
          brandId
        }
        survey {
          surveyTitle
          description
          previewImageUrl
          surveyQuestions {
            description
            type
            required
            minResponseLength
            maxResponseLength
            minValue
            responseOptions
            responseOptionsMetaData
            canSelectMultipleResponses
            imageUrl
          }
        }
      }
    }
  }
`;

export const getUnauthedSurveysForUser = gql`
  query getUnauthedSurveysForUser(
    $brandclubIds: [String!]
    $includedCampaignIds: [String!]
    $page: PageFilter
  ) {
    UnauthedSurveysForUser(
      brandclubIds: $brandclubIds
      includedCampaignIds: $includedCampaignIds
      page: $page
    ) {
      hasMore
      nextPage
      items {
        campaignId
        extendedAttributes
        brandclub {
          imageUrl
          name
          brandclubId
          brandId
        }
        survey {
          surveyTitle
          description
          previewImageUrl
          surveyQuestions {
            description
            type
            required
            minResponseLength
            responseOptions
            responseOptionsMetaData
            canSelectMultipleResponses
            imageUrl
          }
        }
      }
    }
  }
`;
