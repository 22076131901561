import {
  AddCircleIcon,
  BookmarkStarIcon,
  SmartphoneIcon,
  brandclub_colors,
  brandclubStyles,
  Button,
  ButtonProps,
  hexadecimal,
  getBrandLogo,
} from "@brandclub/common-ui";
import { BrandclubPageType, Page, Site } from "@brandclub/types";
import {
  Backdrop,
  Drawer,
  Grid,
  Popover,
  styled,
  useTheme,
} from "@mui/material";
import React, { useMemo, useRef } from "react";
import { Link, NavLink, useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { SiteBrandingType } from "../../types/misc";
import {
  formatMoney,
  getHeaderContrastingChildColors,
  getHeaderTextColor,
} from "../../utils/StringUtils";
import Loading from "../Loading";
import { useSyncContext } from "../SyncProvider";
import "../pages/AppPages/BrandStore/BrandStoreLandingPageStyle.scss";
import NavCartButton from "./Buttons/NavCartButton";
import NavSearchButton from "./Buttons/NavSearchButton";
import NavUserButton from "./Buttons/NavUserButton";
import CategoriesDropdown from "./CategoriesDropdown";

import SearchDropdown from "./SearchDropdown";
import {
  DESKTOP_HORIZONTAL_PADDING,
  DESKTOP_SCREEN_SIZE,
  MOBILE_HORIZONTAL_PADDING,
  MOBILE_SCREEN_SIZE,
  NAV_BAR_HEIGHT,
  TABLET_HORIZONTAL_PADDING,
  TABLET_SCREEN_SIZE,
  DESKTOP_TOP_BAR_HEIGHT,
  SingleNavType,
} from "./constants";

import CheckoutDrawer from "../Checkout/CheckoutDrawer";
import { useCheckoutDrawer } from "../Checkout/useCheckoutDrawer";
import NavBellButton from "./Buttons/NavBellButton";
import { buildRouteLink } from ".";
import { CreateAccountLink } from "../StoreComponents/StoreLogin/LoginButtons";
import { useStateWithLocationReset } from "../../utils/hooks/useStateWithLocationReset";
import { useSignedIn } from "@/utils/hooks/useSignedIn";

const LOGO_SIZE = 70;
const DRAWER_Z_INDEX = 1000;

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  color: getHeaderTextColor(theme),
}));

export const HeadBarContainer = styled("div")(({ theme }) => ({
  color: getHeaderTextColor(theme),
  background: theme.palette.headerColor?.main || brandclub_colors.blueWhite,
  zIndex: DRAWER_Z_INDEX + 1,
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: `0 5%`,
  boxSizing: "border-box",
  marginBottom: 0,
  height: DESKTOP_TOP_BAR_HEIGHT,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    display: "none",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    display: "none",
  },
  ".back_button": {
    cursor: "pointer",
    height: 20,
    paddingLeft: 2,
    outline: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "none",
    fontSize: 14,
    fontWeight: 500,
    color: getHeaderTextColor(theme),
    ".button_icon": {
      maxHeight: 20,
      maxWidth: 20,
      objectFit: "contain",
      marginRight: 6,
    },
  },
}));

export const HeaderBarInner = styled(Grid)(({ theme }) => ({
  position: "relative",
  maxWidth: DESKTOP_SCREEN_SIZE - DESKTOP_HORIZONTAL_PADDING * 2,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ".bc_logo": {
    cursor: "pointer",
    marginRight: 80,
    maxHeight: 35,
    maxWidth: 125,
  },
  ".icon_buttons": {
    color: getHeaderTextColor(theme),
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    flexDirection: "row-reverse",
  },
}));

const HeaderLink = styled("div")({
  display: "flex",
  alignItems: "center",

  fontSize: 18,
  fontWeight: 600,
  gap: 11,
  ".label": {},
});

const BrandLogo = styled("div")({
  height: LOGO_SIZE,
  width: LOGO_SIZE,

  a: {
    height: LOGO_SIZE,
    width: LOGO_SIZE,
    display: "flex",
  },
  display: "flex",
  alignItems: "center",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    position: "relative",
    top: "unset",
    left: "unset",
  },

  img: {
    objectFit: "contain",
    maxHeight: LOGO_SIZE,
    maxWidth: LOGO_SIZE,
    borderRadius: 999,
    border: "1px solid #f6f8fa",
    "&.no_radius": {
      borderRadius: 0,
    },
  },
});

const NavBalance = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 12,
  fontWeight: 600,
  ...getHeaderContrastingChildColors(theme),
  borderRadius: 99,
  padding: "0 13px",
  height: 25,
  margin: "0px",
}));

const BarLeft = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "nowrap",
  flex: 1,
  ".breaker": {
    margin: "0 15px",
    borderLeft: `1.5px solid ${theme.palette.primary.main}`,
    height: "18px",
  },
  a: {
    fontSize: 16,
    fontWeight: 500,
    textDecoration: "none",
    color: getHeaderTextColor(theme),
  },
}));

const BarRight = styled(Grid)(({ theme }) => ({
  display: "flex",
  userSelect: "none",
  alignItems: "center",
  justifyContent: "flex-end",
  flexWrap: "nowrap",
  width: "fit-content",

  ".breaker": {
    margin: "0 15px",
    borderLeft: `1.5px solid ${theme.palette.primary.main}`,
    height: "18px",
  },
  a: {
    fontSize: 16,
    fontWeight: 500,
    textDecoration: "none",
    color: getHeaderTextColor(theme),
  },
}));

const BarCenter = styled(Grid)({
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  position: "absolute",
  width: "fit-content",
});

export const NavigationContainer = styled("div")(({ theme }) => ({
  color: getHeaderTextColor(theme),
  background: brandclub_colors.white,
  zIndex: DRAWER_Z_INDEX - 1,
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: `0 5%`,
  boxSizing: "border-box",

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    padding: `0 ${TABLET_HORIZONTAL_PADDING}px`,
    ".desktop_nav_links": {
      display: "none",
    },
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: `0 ${MOBILE_HORIZONTAL_PADDING}px`,
  },
}));

export const NavBarContainer = styled(Grid)({
  position: "relative",
  maxWidth: DESKTOP_SCREEN_SIZE - DESKTOP_HORIZONTAL_PADDING * 2,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  height: NAV_BAR_HEIGHT,
  boxSizing: "border-box",
  padding: "35px 0px 30px 0",

  borderBottom: `2px solid #f6f8fa`,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  ".logo_left": {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    gap: 50,
    right: `${LOGO_SIZE + 50}px`,
  },
  ".logo_right": {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    gap: 50,
    left: `${LOGO_SIZE + 50}px`,
  },

  ".nav_button": {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    fontSize: 18,
    fontWeight: 600,
    padding: "0px 0px",
    cursor: "pointer",
  },
});
const AuthHeaderContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  gap: 50,
});

const BrandstoreShopTab = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      variant="text"
      className="nav_button"
      sx={(theme) => ({
        backgroundColor: "transparent",
        color: getHeaderTextColor(theme),
        fontSize: 18,
        fontWeight: 600,
      })}
      {...props}
    >
      <span>{children}</span>
    </Button>
  );
};

const BrandstoreShopLink = (props: {
  siteBrandingType: SiteBrandingType;
  onClick?: VoidFunction;
}) => {
  switch (props.siteBrandingType) {
    case SiteBrandingType.CustomStore:
      return (
        <BrandstoreShopTab onClick={props.onClick}>Shop</BrandstoreShopTab>
      );
    case SiteBrandingType.UniversalStore:
      return (
        <Link to={"/shop"} className="link">
          <HeaderLink>
            <div className="label">Shop</div>
          </HeaderLink>
        </Link>
      );
    default:
      return null;
  }
};

// const formatNavMenu = (navMenu: singleNavType[]) => {
//   const ShopNav = navMenu.find(
//     (nav: singleNavType) => nav.name === "BrandShop"
//   );
//   const RewardNav = navMenu.find(
//     (nav: singleNavType) => nav.name === "BrandRewards"
//   );
//   const ExploreNav = navMenu.find(
//     (nav: singleNavType) => nav.name === "BrandExplore"
//   );
//   return { ShopNav, RewardNav, ExploreNav };
// };

interface AuthHeaderProps {
  siteBrandingType: SiteBrandingType;
  onShopLinkClick?: VoidFunction;
}
const UnauthenticatedHeader = ({
  siteBrandingType,
  onShopLinkClick,
}: AuthHeaderProps) => {
  return (
    <AuthHeaderContainer>
      <BrandstoreShopLink
        siteBrandingType={siteBrandingType}
        onClick={onShopLinkClick}
      />
      <CreateAccountLink className="link">
        <HeaderLink>
          <BookmarkStarIcon color="inherit" sx={{ fontSize: 25 }} />
          <div className="label">Sign up</div>
        </HeaderLink>
      </CreateAccountLink>
      <Link to={"/getapp"} className="link">
        <HeaderLink>
          <SmartphoneIcon color="inherit" sx={{ fontSize: 25 }} />
          <div className="label">Get the app</div>
        </HeaderLink>
      </Link>
    </AuthHeaderContainer>
  );
};

const AuthHeader = ({ siteBrandingType, onShopLinkClick }: AuthHeaderProps) => {
  const { rewards } = useAppSelector(({ rewards }) => rewards);
  const theme = useTheme();

  const currentBalance = rewards.currentBalance;
  const {
    loading: syncLoading,
    syncAllRetailer,
    syncStatus,
  } = useSyncContext();

  const syncBtnColors = useMemo(
    () => getHeaderContrastingChildColors(theme),
    [theme]
  );

  return (
    <AuthHeaderContainer>
      <BrandstoreShopLink
        siteBrandingType={siteBrandingType}
        onClick={onShopLinkClick}
      />
      {syncLoading ? (
        <div className="link" style={{ cursor: "pointer" }}>
          <HeaderLink>
            <div className="label">Loading</div>
            <Loading storeSpin size={25} />
          </HeaderLink>
        </div>
      ) : (
        <>
          <div
            className="link"
            onClick={syncAllRetailer}
            style={{ cursor: "pointer" }}
          >
            <HeaderLink>
              <div className="label">
                {syncStatus === "syncing" ? "Syncing" : "Sync"}
              </div>
              {syncStatus === "syncing" ? (
                <Loading storeSpin size={25} />
              ) : (
                <AddCircleIcon
                  backgroundColor={syncBtnColors.backgroundColor}
                  sx={(theme) => ({
                    fontSize: 25,
                    color: syncBtnColors.color,
                  })}
                />
              )}
            </HeaderLink>
          </div>
        </>
      )}
      {currentBalance ? (
        <Link to={"/dashboard/rewards/balance"} className="link">
          <HeaderLink>
            <div className="label">Rewards</div>
            <NavBalance>{formatMoney(currentBalance)}</NavBalance>
          </HeaderLink>
        </Link>
      ) : null}
    </AuthHeaderContainer>
  );
};

const DesktopNav = ({
  backdropOpen,
  siteBrandingType,
  routePrefix,
  persistentQueryParams,
  handleSearchClose,
  onSubmitSearch,
  searchInput,
  setSearchInput,
  handleSearchOpen,
  searchOpen,
  pageContext,
  filterDialogOpen,
  setFilterDialogOpen,
  filterCount,
  setFilterCount,
  navMenu,
  site,
  cartCount,
  brandHeaderLogo,
}: {
  backdropOpen: boolean;
  siteBrandingType: SiteBrandingType;
  routePrefix: string;
  persistentQueryParams: string;
  handleSearchClose: () => void;
  onSubmitSearch: () => void;
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  handleSearchOpen: (event: React.MouseEvent<HTMLDivElement>) => void;
  searchOpen: boolean;
  pageContext: any;
  filterDialogOpen: boolean;
  setFilterDialogOpen: (filterDialogOpen: boolean) => void;
  filterCount: number;
  setFilterCount: (filterCount: number) => void;
  navMenu: SingleNavType[];
  site: Site | undefined;
  cartCount: number;
  brandHeaderLogo: string;
}) => {
  const [categoriesOpen, setCategoriesOpen] = useStateWithLocationReset(false);
  const shopAnchorEl = useRef<HTMLDivElement | null>(null);
  const searchAnchorEl = useRef<HTMLDivElement | null>(null);
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const isCustomStore = siteBrandingType === SiteBrandingType.CustomStore;
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const { signedIn } = useSignedIn();
  const navigate = useNavigate();
  const homePagePathMatch = useMatch("/");
  const handleCategoriesClose = () => {
    setCategoriesOpen(false);
  };
  const handleCategoriesOpen = () => {
    setCategoriesOpen(true);
  };
  const {
    syncStatus,

    latestSyncBatchId,
    sessionRewardSummaryDisplayStatus,
  } = useSyncContext();

  const { isOpen, closeDrawer, openDrawer } = useCheckoutDrawer();

  const onHomePage = homePagePathMatch?.pattern?.end;
  const topBarOffset = Math.max(0, DESKTOP_TOP_BAR_HEIGHT - window.scrollY);
  const shopNav = navMenu.find((nav) => nav.name === "BrandShop");

  return (
    <>
      <Backdrop
        open={backdropOpen || categoriesOpen}
        sx={(theme) => ({
          zIndex: 900,
          top: 0, // should not cover header
          backgroundColor: hexadecimal(theme.palette.primary.main)(21),
        })}
      />
      <HeadBarContainer
        ref={(ref) => {
          searchAnchorEl.current = ref;
          if (isCustomStore) {
            shopAnchorEl.current = ref;
          }
        }}
      >
        <HeaderBarInner container wrap="nowrap">
          <BarLeft container>
            <Link to={"/"} style={{ display: "flex", alignItems: "center" }}>
              <img
                loading="lazy"
                src={brandHeaderLogo}
                className={"bc_logo"}
                alt="bd_logo"
              ></img>
            </Link>
            {userProfile ? (
              <AuthHeader
                siteBrandingType={siteBrandingType}
                onShopLinkClick={handleCategoriesOpen}
              />
            ) : (
              <UnauthenticatedHeader
                siteBrandingType={siteBrandingType}
                onShopLinkClick={handleCategoriesOpen}
              />
            )}
          </BarLeft>
          <BarRight container>
            <div className="icon_buttons">
              {onHomePage ? (
                <div style={{ flex: 1 }} />
              ) : (
                <NavSearchButton handleClick={handleSearchOpen} />
              )}
              {userProfile &&
              syncStatus === "normal" &&
              latestSyncBatchId &&
              sessionRewardSummaryDisplayStatus !== "viewed" ? (
                <NavBellButton
                  animated={true}
                  onClick={() => {
                    navigate(`/sync/summary/${latestSyncBatchId}`);
                  }}
                />
              ) : null}
              <NavUserButton
                onClick={() => {
                  signedIn ? navigate(`/dashboard/me`) : navigate(`/signin`);
                }}
              />
              <NavCartButton
                count={cartCount}
                id="toggle-cart-button"
                onClick={(e) => {
                  // The click outside hook handles closing if they click on this button
                  if (!isOpen) {
                    openDrawer();
                  }
                }}
              />
            </div>
          </BarRight>
        </HeaderBarInner>
      </HeadBarContainer>

      {siteBrandingType !== SiteBrandingType.UniversalStore &&
      !isCustomStore ? (
        <NavigationContainer className="navigationContainer" ref={shopAnchorEl}>
          <NavBarContainer>
            <BarCenter
              container
              wrap="nowrap"
              style={searchOpen ? { display: "none" } : {}}
              className="desktop_nav_links"
            >
              <div className="logo_left">
                <BrandstoreShopTab onClick={handleCategoriesOpen}>
                  Shop
                </BrandstoreShopTab>

                <StyledNavLink
                  to={`${routePrefix}/branddashboard/rewards/`}
                  className="nav_button text-link-wrapper"
                >
                  <div className="text-link">Rewards</div>
                </StyledNavLink>
              </div>

              <BrandLogo>
                <Link to={`${routePrefix}/home?${persistentQueryParams || ""}`}>
                  <img
                    loading="lazy"
                    src={getBrandLogo(
                      brandEntity?.id || appConfig?.domainConfig.brandId
                    )}
                    className={""}
                    alt="bd_logo"
                  ></img>
                </Link>
              </BrandLogo>

              <div className="logo_right">
                <StyledNavLink
                  to={buildRouteLink(
                    [{}],
                    routePrefix,
                    "/branddashboard/me",
                    persistentQueryParams
                  )}
                  className="nav_button text-link-wrapper"
                >
                  <div className="text-link">Dashboard</div>
                </StyledNavLink>

                <StyledNavLink
                  to={buildRouteLink(
                    [{}],
                    routePrefix,
                    "/offers",
                    persistentQueryParams
                  )}
                  className="nav_button text-link-wrapper"
                >
                  <div className="text-link">Offers</div>
                </StyledNavLink>
              </div>
            </BarCenter>
          </NavBarContainer>
        </NavigationContainer>
      ) : null}

      {searchAnchorEl.current && (
        <Popover
          id="search-popover"
          open={searchOpen}
          anchorEl={searchAnchorEl.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: 0,
            horizontal: "center",
          }}
          PaperProps={{
            sx: {
              boxShadow: "none",
              left: `0 !important`,
              right: `0px !important`,
              marginLeft: "auto",
              marginRight: "auto",
              width: "100vw",
              minHeight: "448px",
              maxWidth: "100vw",
              maxHeight: `calc(100vh - ${topBarOffset}px)`,
              borderRadius: "0",
              ...brandclubStyles.customScrollbar,
            },
          }}
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
          onClose={handleSearchClose}
        >
          <SearchDropdown
            onSubmitSearch={onSubmitSearch}
            onClose={handleSearchClose}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            persistentQueryParams={persistentQueryParams}
            pageContext={pageContext}
            filterDialogOpen={filterDialogOpen}
            setFilterDialogOpen={setFilterDialogOpen}
            filterCount={filterCount}
            setFilterCount={setFilterCount}
          />
        </Popover>
      )}

      {shopAnchorEl && shopNav ? (
        <Popover
          open={categoriesOpen}
          anchorEl={shopAnchorEl.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: 0,
            horizontal: "center",
          }}
          slotProps={{
            paper: {
              sx: {
                boxShadow: "none",
                left: `0 !important`,
                right: `0px !important`,
                marginLeft: "auto",
                marginRight: "auto",
                width: "100vw",
                maxWidth: "100vw",
                maxHeight: `calc(100vh - ${topBarOffset + NAV_BAR_HEIGHT}px)`,
                borderRadius: "0",
                backgroundColor: "none",
                ...brandclubStyles.customScrollbar,
              },
            },
          }}
          onClose={handleCategoriesClose}
        >
          <CategoriesDropdown
            categoryNav={shopNav}
            isCustomGrouped={(site?.pages || []).some(
              (p: Page) => p.pageType === BrandclubPageType.CustomCategoryGroup
            )}
            handleCategoriesClose={handleCategoriesClose}
          />
        </Popover>
      ) : null}
      <Drawer
        id="cart-popover"
        open={isOpen}
        sx={{
          zIndex: DRAWER_Z_INDEX,
          width: 462,
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            borderRadius: "0",
            ...brandclubStyles.customScrollbar,
          },
        }}
        slotProps={{
          backdrop: {
            sx: (theme) => ({
              backgroundColor: hexadecimal(theme.palette.primary.main)(21),
            }),
          },
        }}
        anchor="right"
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        onClose={() => {
          closeDrawer();
        }}
      >
        <div style={{ paddingTop: topBarOffset }}></div>
        <CheckoutDrawer />
      </Drawer>
    </>
  );
};

export default DesktopNav;
