import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBrandClubById, getEntityForLandingPage } from "../../../api";
import ReduxStore from "../../types";
import { ThunkExtraArguments } from "@/redux/store";

export const getBrandEntity = createAsyncThunk(
  "/brandEntity/getBrandEntity",
  async ({ id }: { id?: string }, thunkAPI) => {
    const { mainEntity } = thunkAPI.getState() as ReduxStore;
    if (
      `${mainEntity?.id}` === `${id}` &&
      mainEntity?.entity &&
      mainEntity?.type === "brand"
    ) {
      return new Promise((resolve, reject) => {
        resolve({ data: { ...mainEntity?.entity } } as any);
      });
    }
    const entityResponse = await getEntityForLandingPage({
      entityId: id as any,
      entityType: "brand",
    });
    return { data: entityResponse.data };
  }
);

export const getBrandclubEntity = createAsyncThunk<
  any,
  { id?: number },
  { extra: ThunkExtraArguments }
>("/brandEntity/getBrandclubEntity", async ({ id }, thunkAPI) => {
  return await thunkAPI.extra.apolloClient.query({
    query: getBrandClubById,
    variables: {
      brandId: id,
    },
    fetchPolicy: "network-only",
  });
});
