import { useEffect, useMemo, useState } from "react";
import useStoreType from "./useStoreType";
import { getAppThemeDraft, getCustomTheme, ThemeResponse } from "../../api";
import { StoreBrandingType } from "../../types/misc";
import { useAppSelector } from "../../redux/hooks";
import { AppConfigTheme } from "../../redux/types";
import { ThemeOptions } from "@mui/material/styles";

class ThemeDraft {
  /**
   * The identifier for the theme draft in the URL and session storage
   */
  private static readonly THEME_DRAFT_IDENTIFIER = "themedraftid";

  private static getIdFromUrl(): string | null {
    const params = new URLSearchParams(window.location.search);
    const id = params.get(this.THEME_DRAFT_IDENTIFIER);
    if (!id || id === "undefined" || id === "null") {
      return null;
    }
    return id;
  }

  private static getIdFromStorage(): string | null {
    return sessionStorage.getItem(this.THEME_DRAFT_IDENTIFIER);
  }

  static getCurrentId(): string | null {
    const urlId = this.getIdFromUrl();
    if (urlId) {
      this.saveId(urlId);
      return urlId;
    }
    return this.getIdFromStorage();
  }

  private static saveId(id: string): void {
    sessionStorage.setItem(this.THEME_DRAFT_IDENTIFIER, id);
  }

  static clearId(): void {
    sessionStorage.removeItem(this.THEME_DRAFT_IDENTIFIER);
  }

  static async getTheme(appId: string): Promise<ThemeResponse | null> {
    const id = this.getCurrentId();
    if (!id) {
      return null;
    }

    try {
      const response = await getAppThemeDraft(appId, id);
      if (response?.status === "success") {
        this.saveId(response.draftId);
        return { ...response.brandAppTheme, draftId: response.draftId };
      }
      if (response.status === "notFound") {
        this.clearId();
        return null;
      }
      throw new Error(response.errorMessage);
    } catch (error) {
      console.error("Error fetching theme draft:", error);
      return null;
    }
  }
}

const buildDtcTheme = (theme: ThemeResponse): ThemeOptions => ({
  palette: {
    primary: {
      main: theme.primary,
    },
    secondary: {
      main: theme.secondary,
    },
  },
  fontWeight: theme.dtcFonts?.fontWeight,
  customTypography: {
    fontFamily: theme.dtcFonts?.fontFamily as string,
    baseFontSize: theme.dtcFonts?.baseFontSize as number,
    fontFamilyLink: theme.dtcFonts?.fontFamilyLink as string,
  },
  barFonts: {
    fontFamily: theme.barFonts?.fontFamily as string,
    baseFontSize: theme.barFonts?.baseFontSize as number,
    fontFamilyLink: theme.barFonts?.fontFamilyLink as string,
  },
  dtcFonts: {
    fontFamily: theme.dtcFonts?.fontFamily as string,
    baseFontSize: theme.dtcFonts?.baseFontSize as number,
    fontFamilyLink: theme.dtcFonts?.fontFamilyLink as string,
    viewBagTextOffsetY: theme.dtcFonts?.viewBagTextOffsetY as number,
  },
  activeInpageCheckout: theme.activeInpageCheckout,
  baseFontSize: theme.dtcFonts?.baseFontSize as number,
  typography: {
    fontFamily: [
      theme.dtcFonts?.fontFamily as string,
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  customConnectReward: theme.customConnectReward,

  componentStyleOverrides: theme.componentStyleOverrides,
  globalButtonRadius: theme.globalButtonRadius,
  customRewardPillColor: theme.customRewardPillColor,
  dtcGetApp: theme.dtcGetApp,
  inpageCheckoutComponent: theme.inpageCheckoutComponent,
  dtc: true,
});

const buildCustomStoreTheme = (theme: AppConfigTheme): ThemeOptions => ({
  palette: {
    primary: {
      main: theme.primaryColor,
    },
    secondary: {
      main: theme.secondaryColor,
    },
    tertiary: {
      main: theme.tertiaryColor,
    },
    headerColor: {
      main: theme.headerColor,
    },
  },
  baseFontSize: theme.baseFontSize,
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const useCustomTheme = () => {
  const [theme, setTheme] = useState<ThemeOptions | undefined>(undefined);
  const brandAppThemeConfig = useAppSelector(
    ({ appConfig }) => appConfig?.brandAppThemeConfig
  );
  const storeType = useStoreType();
  const isDtc = storeType === StoreBrandingType.CustomDTCStore;

  const brandAppTheme = useMemo(() => {
    return (
      brandAppThemeConfig?.theme &&
      buildCustomStoreTheme(brandAppThemeConfig.theme)
    );
  }, [brandAppThemeConfig]);

  useEffect(() => {
    const fetchDtcTheme = async () => {
      const appId = window.location.origin.split("https://")[1];
      const theme =
        (await ThemeDraft.getTheme(appId)) ?? (await getCustomTheme());
      setTheme(theme && buildDtcTheme(theme));
      if (theme?.dtcFonts?.fontFamily) {
        const link = document.createElement("link");
        link.rel = "preload stylesheet";
        link.href = theme.dtcFonts.fontFamilyLink || theme.dtcFonts.fontFamily;
        const barLink = document.createElement("link");
        barLink.rel = "preload stylesheet";
        barLink.href =
          (theme.barFonts?.fontFamilyLink || theme.barFonts?.fontFamily) ?? "";
        document.head.appendChild(barLink);
        document.head.appendChild(link);
      }
    };
    if (isDtc) {
      fetchDtcTheme();
    }
  }, [isDtc]);

  switch (storeType) {
    case StoreBrandingType.CustomDTCStore:
      return { theme };
    case StoreBrandingType.CustomStore:
      return { theme: brandAppTheme };
    default:
      return undefined;
  }
};
