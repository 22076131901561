import { styled } from "@mui/material";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";

interface CardGridProps {
  minCardWidth?: number;
  minCardWidthMobile?: number;
}
const DEFAULT_MIN_CARD_WIDTH = 220;
const DEFAULT_MOBILE_MIN_CARD_WIDTH = 172;
export const CardGrid = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "minCardWidth" && prop !== "minCardWidthMobile",
  label: "CardGrid",
})<CardGridProps>(({ minCardWidth, minCardWidthMobile }) => {
  minCardWidth = minCardWidth ?? DEFAULT_MIN_CARD_WIDTH;
  minCardWidthMobile = minCardWidthMobile ?? DEFAULT_MOBILE_MIN_CARD_WIDTH;
  return {
    width: "100%",
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(${minCardWidth}px, 1fr))`,
    justifyContent: "space-between",
    gridGap: 20,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${
        minCardWidth - 5
      }px,  1fr))`,
      gridGap: 10,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${minCardWidthMobile}px,  1fr))`,
      gridGap: 10,
    },
  };
});

export const LongCardList = styled("div", {
  label: "LongCardList",
})({
  width: "100%",
  display: "grid",
  gridTemplateColumns: `repeat(1, 1fr)`,
  justifyContent: "space-between",
  gridGap: 20,

  [`@media screen and (max-width: ${1250}px)`]: {
    gridTemplateColumns: `repeat(1, 1fr)`,
  },
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    gridTemplateColumns: `repeat(1, 1fr)`,
    // padding: `0px ${MOBILE_HORIZONTAL_PADDING}px`,
  },
  [`@media screen and (max-width: ${
    (TABLET_SCREEN_SIZE + MOBILE_SCREEN_SIZE) / 2
  }px)`]: {
    gridTemplateColumns: `repeat(1, 1fr)`,
    gridGap: 10,
  },
});

export const CardGridForThree = styled("div", {
  label: "CardGridForThree",
})({
  width: "100%",
  display: "grid",
  gridTemplateColumns: `repeat(3, 1fr)`,
  justifyContent: "space-between",
  gridGap: 20,
  [`@media screen and (max-width: ${1250}px)`]: {
    gridTemplateColumns: `repeat(3, 1fr)`,
  },
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    gridTemplateColumns: `repeat(3, 1fr)`,
    // padding: `0px ${MOBILE_HORIZONTAL_PADDING}px`,
  },
  [`@media screen and (max-width: ${
    (TABLET_SCREEN_SIZE + MOBILE_SCREEN_SIZE) / 2
  }px)`]: {
    gridTemplateColumns: `repeat(2, 1fr)`,
    gridGap: 10,
  },
});

export const CardGridForFour = styled("div", {
  label: "CardGridForFour",
})({
  width: "100%",
  display: "grid",
  gridTemplateColumns: `repeat(4, 1fr)`,
  justifyContent: "space-between",
  gridGap: 20,
  [`@media screen and (max-width: ${1250}px)`]: {
    gridTemplateColumns: `repeat(3, 1fr)`,
  },
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    gridTemplateColumns: `repeat(3, 1fr)`,
    // padding: `0px ${MOBILE_HORIZONTAL_PADDING}px`,
  },
  [`@media screen and (max-width: ${
    (TABLET_SCREEN_SIZE + MOBILE_SCREEN_SIZE) / 2
  }px)`]: {
    gridTemplateColumns: `repeat(2, 1fr)`,
    gridGap: 10,
  },
});

export const LongCardGrid = styled("div", {
  label: "LongCardGrid",
})({
  width: "100%",
  display: "grid",
  gridTemplateColumns: `repeat(2, 1fr)`,
  justifyContent: "space-between",
  gridGap: 20,
  [`@media screen and (max-width: ${1250}px)`]: {
    gridTemplateColumns: `repeat(2, 1fr)`,
  },
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    gridTemplateColumns: `repeat(1, 1fr)`,
    // padding: `0px ${MOBILE_HORIZONTAL_PADDING}px`,
  },
  [`@media screen and (max-width: ${
    (TABLET_SCREEN_SIZE + MOBILE_SCREEN_SIZE) / 2
  }px)`]: {
    gridTemplateColumns: `repeat(1, 1fr)`,
    gridGap: 10,
  },
});
