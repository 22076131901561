import { createAsyncThunk } from "@reduxjs/toolkit";
import { UNAUTHENTICATED_SEARCH_PRODUCTS } from "../../../api";
import { ProductOperation } from "../../../api/graphQl/unauthenticated/Product";
import { ThunkExtraArguments } from "@/redux/store";

export const getTopProduct = createAsyncThunk<
  any,
  { brandId: number },
  { extra: ThunkExtraArguments }
>("/brandApp/getTopProduct", async ({ brandId }, thunkAPI) => {
  const productEntityRes = await thunkAPI.extra.apolloClient.query({
    query: UNAUTHENTICATED_SEARCH_PRODUCTS,
    variables: {
      page: { start: 0, size: 1 },
      brandId: brandId,
      computeFacets: false,
      isBrandClubRead: true,
    },
  });

  return productEntityRes?.data?.[ProductOperation.UnauthenticatedSearch]
    ?.products?.[0];
});
