import { decodeHTMLCharacters } from "@stackline/ui";

import { getDefaultProductImage } from "@brandclub/common-ui";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { ReviewProductResponse } from "../../../../../../types/misc";
import { ProductRewardSearchResult } from "../../../../../../types/search";
import { getFallbackProductImage } from "../../../../../../utils/media";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import { OutlineButton } from "../../../../../StoreComponents/StoreButton";
import FiveStarRating from "./FiveStarRating";
import { FallbackImage } from "@brandclub/common-ui";

const ProductReviewCard = styled("div", { label: "ProductReviewCard" })(({ theme }) => ({
  border: "1px solid #dcdddc",
  width: "100%",
  height: "100%",
  backgroundColor: "#fff",
  borderRadius: 12,
  padding: `25px 21px 30px 21px`,
  cursor: "pointer",
  boxSizing: "border-box",
  transition: "background-color 0.4s ease-in-out",

  "*": {
    mixBlendMode: "multiply",
  },

  "&:hover": {
    ".card_button": {
      transition: "all 0.3s ease-in-out",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },

  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    padding: 10,
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: "100%",
  },
  ".reward_info": {
    "*": {
      mixBlendMode: "unset",
    },
  },
  ".image": {
    // width: `calc(100% - 24px)`,
    width: `60%`,
    aspectRatio: "1/1",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: `10px auto 10px`,
    img: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
  },
  ".title": {
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
    marginTop: 30,
    width: "100%",
    overflow: "hidden",
    letterSpacing: 0.36,
    WebkitLineClamp: 3,
    lineHeight: 1.5,
    height: `4.5em`, // line height * 2
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    [`@media screen and (max-width: ${1250}px)`]: {
      fontSize: 12,
    },
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
      WebkitLineClamp: 2,

      height: `3em`, // line height * 2
    },
  },
  ".price": {
    color: theme.palette.primary.main,
    fontSize: 17,
    fontWeight: 600,
    marginTop: 12,
    marginBottom: 20,
    display: "flex",
    columnGap: 14,
    alignItems: "center",

    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      columnGap: 7,
      fontSize: 17,
      marginTop: 8,
      marginBottom: 15,
    },
  },
}));

const WriteButton = styled(OutlineButton)({
  width: "100%",
  height: 38.5,
  whiteSpace: "nowrap",

  fontSize: 12,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    columnGap: 7,
    fontSize: 11,
  },
});

const ReviewCard = ({
  product,
  className,
}: {
  product: Partial<ReviewProductResponse & ProductRewardSearchResult>;
  className?: string;
}) => {
  const theme = useTheme();
  const smBreakpoint = theme.breakpoints.values.sm;
  const tabletView = useMediaQuery(`(min-width: ${smBreakpoint}px)`);
  const mobileView = !tabletView;
  // const {
  //   affiliateRewardAmount,
  //   hasActiveInstantReward,
  //   matchedProducts,
  //   retailerId,
  //   retailPrice,
  //   stacklineSku,
  //   title,
  //   totalRewardAmount,
  // } = product as any;
  // const urlSearchParams = getAppConsistentParams(window.location.search);
  // const targetUrl = `/${escapeUriPath(
  //   title?.substring(0, 40),
  //   "-"
  // )}/p/${stacklineSku}/product?${urlSearchParams.toString()}`;

  const cleanProductTitle = decodeHTMLCharacters(product?.title || "");

  return (
    <Link
      to={`/review/${product.stacklineSku}`}
      style={{ textDecoration: "none" }}
      state={{ product: product }}
    >
      <ProductReviewCard className={className}>
        <div className="image">
          <FallbackImage
            src={getDefaultProductImage(product?.stacklineSku || "")}
            fallbackImage={getFallbackProductImage(product?.stacklineSku || "")}
            alt={"s"}
          />
        </div>

        <div className="title">{cleanProductTitle}</div>

        <div className="price">
          <FiveStarRating size={mobileView ? 17 : 23} />
        </div>
        <WriteButton className="card_button">Write review</WriteButton>
      </ProductReviewCard>
    </Link>
  );
};

export default ReviewCard;
