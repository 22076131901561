import {
  Button,
  CustomDtcBagButton,
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "@brandclub/common-ui";
import {
  ClickAwayListener,
  Popper,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

import { getCartCounts } from "@/redux/selectors";
import { useCustomTheme } from "@/utils/hooks/useCustomTheme";
import HamburgerMenuButton from "../pages/AppPages/Dashboard/DashboardBottomBar/HamburgerMenuButton";
import { useAppSelector } from "@/redux/hooks";
import { useNavigate } from "react-router-dom";
import { OutlineButton } from "./StoreButton";
import { scaleFont } from "@/utils/font";
import { usePrefetchDTCOffers } from "../pages/AppPages/Dashboard/Offers/usePrefetchDTCOffers";

const BarOutlineButton = styled(OutlineButton)(({ theme }) => ({
  fontSize: scaleFont(theme.baseFontSize, 16),
  fontWeight: 500,
  borderWidth: 1,
  borderColor: "#dcdddc80",
  width: "fit-content",
  height: 52,
  padding: "0px 20px",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    height: 46,
    fontSize: scaleFont(theme.baseFontSize, 14),
  },
}));

const menuItems = [
  {
    label: "Dashboard",
    to: "/me",
  },
  {
    label: "Purchases",
    to: "/purchase",
  },
  {
    label: "Accounts",
    to: "/accounts",
  },
  {
    label: "Rewards",
    to: "/rewards/balance",
  },
  {
    label: "Offers",
    to: "/offers",
  },
  {
    label: "Personal",
    to: "/profile",
  },
];

const HeaderButtonComponent = styled(Button)<{ inPageCheckout: boolean }>(
  ({ inPageCheckout }) => ({
    display: inPageCheckout ? "flex" : "none",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    position: "absolute",
    right: "21%",
    [`@media (max-width: 1350px)`]: {
      right: "5%",
    },
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {},
  })
);

const HeaderBagButtonText = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    display: "none",
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    display: "none",
  },

  ".header_bag_button_text": {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

export const HeaderBagButton = () => {
  const theme = useCustomTheme();
  const inPageCheckout = !!theme?.theme?.activeInpageCheckout;
  const viewBagTextOffsetY = theme?.theme?.dtcFonts?.viewBagTextOffsetY;
  const cartCount = useAppSelector(getCartCounts);

  const isMobile = useMediaQuery(`(max-width: ${TABLET_SCREEN_SIZE}px)`);
  const navigate = useNavigate();

  const handleViewBag = () => {
    navigate("/mybag");
  };

  if (!cartCount) {
    return null;
  }
  return (
    <HeaderButtonComponent
      variant="text"
      inPageCheckout={inPageCheckout}
      onClick={handleViewBag}
    >
      <HeaderBagButtonText>
        <div
          className="header_bag_button_text"
          style={{
            transform: `translateY(${viewBagTextOffsetY}%)`,
          }}
        >
          View bag
        </div>
      </HeaderBagButtonText>

      <CustomDtcBagButton
        handleClick={handleViewBag}
        cartCount={cartCount}
        styleData={theme?.theme}
        mobile={isMobile}
        dtc
      />
    </HeaderButtonComponent>
  );
};

const HamburgerComponent = styled("div")<{ inPageCheckout: boolean }>(
  ({ inPageCheckout }) => ({
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    background: "none",
    gap: "8px",
    position: "absolute",
    left: "21%",
    display: "none",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      display: inPageCheckout ? "flex" : "none",
      left: "5%",
    },
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      display: inPageCheckout ? "flex" : "none",
      left: "5%",
    },
  })
);

const Dropdown = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  padding: "12px 8px",
  boxSizing: "border-box",
  border: "1px solid #dcdddc80",
  backgroundColor: " #ffffff",
  shadow: "0 3px 15px 0 rgba(195, 188, 188, 0.15)",
  borderRadius: "8px",
});

export const HeaderMenuResonsiveMenu = () => {
  const theme = useTheme();
  const prefetchAllDTCOffers = usePrefetchDTCOffers();
  const inPageCheckout = !!theme?.activeInpageCheckout;

  const outlinedButtonTextColor = theme?.componentStyleOverrides?.outlinedButton
    ?.enabled
    ? theme?.componentStyleOverrides?.outlinedButton?.primary
    : theme.palette.primary.main;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      // if anchorEl is null, we are opening the menu, so we prefetch all DTC offers
      prefetchAllDTCOffers();
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "menu-popper" : undefined;

  return (
    <>
      <HamburgerComponent
        inPageCheckout={inPageCheckout}
        onClick={handleToggleMenu}
        aria-describedby={id}
        role="button"
      >
        <HamburgerMenuButton width={22.4} height={18} />
      </HamburgerComponent>
      <Popper
        placement="bottom-start"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 28],
            },
          },
        ]}
        className="menu-popper"
        sx={{
          zIndex: 1,
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
      >
        <ClickAwayListener onClickAway={handleToggleMenu as any}>
          <Dropdown>
            {menuItems.map((item) => {
              return (
                <BarOutlineButton
                  sx={{
                    color: outlinedButtonTextColor,
                    fontWeight: theme.fontWeight,
                  }}
                  key={item.label}
                  onClick={(e) => {
                    navigate(item.to);
                    handleToggleMenu(e);
                  }}
                >
                  {item.label}
                </BarOutlineButton>
              );
            })}
          </Dropdown>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
