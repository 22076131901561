import { useTheme } from "@mui/material";
import React from "react";

const HamburgerMenuButton = ({
  width = 23.454,
  height = 21.183,
}: {
  width?: number;
  height?: number;
}) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23.454 21.183"
    >
      <g data-name="Group 20475">
        <g data-name="Group 8982">
          <path
            data-name="Line 311"
            transform="translate(1.2 1.2)"
            style={{
              fill: "none",
              stroke: theme.palette.primary.main,
              strokeLinecap: "round",
              strokeWidth: "2.4px",
            }}
            d="M0 0h21.054"
          />
          <path
            data-name="Line 312"
            transform="translate(1.2 10.591)"
            style={{
              fill: "none",
              stroke: theme.palette.primary.main,
              strokeLinecap: "round",
              strokeWidth: "2.4px",
            }}
            d="M0 0h21.054"
          />
          <path
            data-name="Line 313"
            transform="translate(1.2 19.983)"
            style={{
              fill: "none",
              stroke: theme.palette.primary.main,
              strokeLinecap: "round",
              strokeWidth: "2.4px",
            }}
            d="M0 0h21.054"
          />
        </g>
      </g>
    </svg>
  );
};

export default HamburgerMenuButton;
