import { useEffect, useState } from "react";
import { ShippingAddress, UserRetailerCheckoutOrder } from "../../types";
import { CheckoutInput } from "../CheckoutInput";
import { InputContainer } from "../InputContainer";
import { styled } from "@mui/material";
import {
  OutlineButton,
  SolidButton,
} from "../../../StoreComponents/StoreButton";
import StoreAddressAutocompleteInput, {
  autocompleteAddressType,
} from "../../../StoreComponents/StoreAddressAutocompleteInput";

import { CardNumberVerification } from "card-validator/dist/card-number";
import cardValidator from "card-validator";
import {
  CardBrand,
  getLogoUrlForCardIssuer,
} from "../../../pages/AppPages/Dashboard/Wallet/CardBrandIcon";
import StoreRadio from "../../../StoreComponents/StoreRadio";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { StoreError } from "../../../StoreComponents/StoreError";
import {
  getCardMasking,
  NumberFormatCustom,
} from "@/components/StoreComponents/NumberFormatCustom";
import { AddCircleOutlineIcon } from "@brandclub/common-ui";

const Container = styled("div")(({ theme }) => ({
  marginBottom: 20,
  ".existing": {
    display: "flex",
    flexDirection: "column",
    gap: 6,

    ".info_row": {
      cursor: "pointer",
      height: 30,
      display: "flex",
      alignItems: "flex-start",
      fontSize: 14,
      fontWeight: 500,

      ".icon": {
        width: 30,
        height: 30,
        objectFit: "contain",
        marginRight: 10,
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          width: 20,
          height: 20,
          marginRight: 8,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          width: 20,
          height: 20,

          marginRight: 8,
        },
      },
      ".info_text": {
        [`@media screen and (min-width: ${TABLET_SCREEN_SIZE}px)`]: {
          marginTop: "auto",
          marginBottom: "auto",
        },
      },
      ".add_new_icon": {
        lineHeight: 0,
        marginRight: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 22,
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          fontSize: 17,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 17,
        },
      },
    },
  },
  ".add_new": {
    marginTop: 44,
    ".title": {
      fontSize: 20,
      fontWeight: 600,
    },
  },
  ".actions": {
    marginTop: 30,
    display: "flex",
    gap: 20,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const CancelButton = styled(OutlineButton)({
  fontSize: 14,
  height: 37,
});

const SaveButton = styled(SolidButton)({
  fontSize: 14,
  height: 37,
});

interface CheckoutEditPaymentFormProps {
  billingAddress: UserRetailerCheckoutOrder["billingAddress"];
  handleUpdatePayment: (
    method: "add" | "update",
    cardInfo: {
      cardNumber?: string;
      cardIssuer: string;
      expirationDate: string;
      cvv?: string;
      cardHolderName: string;

      cardNumberEnding?: string;
    },
    address?: Partial<ShippingAddress>
  ) => void;
  cancelEditing: () => void;
  existingAddress?: ShippingAddress;
  availablePayments: UserRetailerCheckoutOrder["availablePaymentMethods"];
  error?: string;
}

const CheckoutEditPaymentForm = ({
  handleUpdatePayment,
  cancelEditing,
  existingAddress,
  availablePayments,
  billingAddress,
  error,
}: CheckoutEditPaymentFormProps) => {
  const [address1, setAddress1] = useState(existingAddress?.address1 || "");
  const [city, setCity] = useState(existingAddress?.city || "");
  const [state, setState] = useState(existingAddress?.state || "");
  const [zipCode, setZipCode] = useState(existingAddress?.zipCode || "");

  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expirateDate, setExpirateDate] = useState("");
  const [cvv, setCvv] = useState("");

  const [cardInfo, setCardInfo] =
    useState<CardNumberVerification["card"] | undefined>(undefined);
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState<number>(-1);

  const numberFormatStr = getCardMasking(cardInfo);

  const canSubmit =
    selectedPaymentIndex >= 0 ||
    (address1 &&
      city &&
      state &&
      zipCode &&
      name &&
      cardNumber &&
      expirateDate &&
      cvv);

  const originSelectedIndex =
    availablePayments?.findIndex((payment) => {
      return payment.isSelected;
    }) ?? -1;

  useEffect(() => {
    if (!availablePayments?.length) {
      setAddNewOpen(true);
    } else {
      setSelectedPaymentIndex(originSelectedIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePayments?.length]);

  const selectedPayment = availablePayments?.[selectedPaymentIndex];

  return (
    <Container>
      <StoreError errorMessage={error} sx={{ marginBottom: 2 }} />
      {availablePayments?.length ? (
        <div className="existing">
          {availablePayments.map((payment, index) => {
            const icon = getLogoUrlForCardIssuer(
              payment.cardIssuer.toLocaleLowerCase() as CardBrand
            );
            return (
              <div
                className="info_row"
                key={payment.cardIssuer + payment.cardNumberEnding}
                onClick={() => {
                  setSelectedPaymentIndex(index);
                  setAddNewOpen(false);
                }}
                style={{
                  fontWeight: selectedPaymentIndex === index ? 600 : 500,
                }}
              >
                <StoreRadio
                  key={payment.cardIssuer + payment.cardNumberEnding}
                  style={{ marginRight: 10 }}
                  sx={{
                    //need to unset inherited padding from mui
                    padding: "unset",
                    [`@media screen and (min-width: ${TABLET_SCREEN_SIZE}px)`]:
                      {
                        marginTop: "auto",
                        marginBottom: "auto",
                      },
                  }}
                  checked={selectedPaymentIndex === index}
                  disableRipple
                  onClick={() => {}}
                />
                {icon ? (
                  <img className="icon" alt="icon" src={icon}></img>
                ) : null}
                <span className="info_text">
                  Ending in {payment?.cardNumberEnding} -{" "}
                  {payment.cardHolderName}
                </span>
              </div>
            );
          })}
          <div
            className="info_row"
            onClick={() => {
              setSelectedPaymentIndex(-1);
              setAddNewOpen(true);
            }}
          >
            <AddCircleOutlineIcon className="add_new_icon" />
            Add new payment method
          </div>
        </div>
      ) : null}

      {addNewOpen ? (
        <div className="add_new">
          <div className="title">Add Payment Method</div>
          <InputContainer mt={"16px"}>
            <CheckoutInput
              label={"Name on Card"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <CheckoutInput
              label={"Card Number"}
              value={cardNumber}
              onChange={(e) => {
                const cardInfo = cardValidator.number(e.target.value);
                setCardInfo(cardInfo?.card);
                setCardNumber(e.target.value);
              }}
              inputProps={{
                format: numberFormatStr ?? "#### #### #### ######",
              }}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                startAdornment: cardInfo?.type ? (
                  <img
                    alt="logo"
                    src={getLogoUrlForCardIssuer(cardInfo?.type as CardBrand)}
                    style={{
                      width: 22,
                      height: 22,
                      objectFit: "contain",
                      marginTop: 18,
                      marginRight: 0,
                      marginLeft: 0,
                    }}
                  ></img>
                ) : null,
              }}
            />
          </InputContainer>
          <InputContainer>
            <CheckoutInput
              label={"Expiration Date"}
              value={expirateDate}
              onChange={(e) => setExpirateDate(e.target.value)}
              inputProps={{
                format: "##/##",
              }}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
            />
            <CheckoutInput
              label={"Security code"}
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <StoreAddressAutocompleteInput
              searchTerm={address1}
              selectAddressCallback={(address: autocompleteAddressType) => {
                setAddress1(address?.line1 || "");
                setCity(address?.city || "");
                setState(address?.state || "");
                setZipCode(address?.zipCode || "");
              }}
              renderInput={(param: any) => (
                <CheckoutInput
                  {...param}
                  placeholder="Begin typing to search"
                  label={"Address Line 1"}
                  onChange={(e) => setAddress1(e.target.value)}
                  value={address1}
                  InputProps={{
                    ...param.InputProps,
                  }}
                  inputProps={{
                    ...param.inputProps,
                    value: address1,
                  }}
                />
              )}
            />
            <CheckoutInput
              label={"City"}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </InputContainer>

          <InputContainer>
            <CheckoutInput
              label={"State"}
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <CheckoutInput
              label={"Zip Code"}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </InputContainer>
        </div>
      ) : null}
      <div className="actions">
        <CancelButton onClick={cancelEditing}>Cancel</CancelButton>
        <SaveButton
          trackedAdditionalData={{
            cardIssuer: selectedPayment?.cardIssuer,
            cardNumber: selectedPayment?.cardNumberEnding,
          }}
          disabled={!canSubmit}
          onClick={() => {
            if (selectedPayment) {
              if (selectedPaymentIndex === originSelectedIndex) {
                // user selected the same payment method, no need to update
                cancelEditing();
              } else {
                // user selected a new payment method, need to update
                handleUpdatePayment(
                  "update",
                  {
                    cardIssuer: selectedPayment.cardIssuer,
                    expirationDate: selectedPayment.expirationDate,

                    cardHolderName: selectedPayment.cardHolderName,
                    cardNumber: selectedPayment.cardNumberEnding,
                  },
                  {
                    ...billingAddress,
                  }
                );
              }
            } else {
              // user created a new payment method, need to update
              handleUpdatePayment(
                "add",
                {
                  cardNumber,
                  cardIssuer: cardInfo?.type || "",
                  expirationDate: expirateDate,
                  cvv,
                  cardHolderName: name,
                },
                {
                  address1,
                  city,
                  state,
                  zipCode,
                }
              );
            }
          }}
        >
          Save
        </SaveButton>
      </div>
    </Container>
  );
};

export default CheckoutEditPaymentForm;
