import { Site } from "@brandclub/types";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";

import { useParams, useSearchParams } from "react-router-dom";
import { SplitSitePagesResponse, getSite, getSiteForLandingPage } from "../api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getBrandEntity } from "../redux/reducers/brandEntity/thunk";
import { getMainEntity } from "../redux/reducers/mainEntity/thunk";
import { escapeUriPath } from "../utils/StringUtils";

import { useSplitClient, useTreatments } from "@splitsoftware/splitio-react";
import { loadCategories } from "../redux/actions/categories";

import { BRANDCLUB_BRAND_ID } from "@brandclub/common-ui";
import { SiteBrandingType, StoreBrandingType } from "../types/misc";
import StoreFullScreenLoading from "./StoreComponents/StoreFullScreenLoading";

export const SiteContext = createContext<SiteContextType>({
  siteBrandingType: SiteBrandingType.UniversalStore,
  site: undefined,
  persistentQueryParams: "",
  previewModeConfig: undefined,
  routePrefix: "",
  abTestingTreatments: {},
});

interface GetSiteBrandingTypeProps {
  storeBrandingType: StoreBrandingType | undefined;
  brandId: number | undefined;
  isBrandStore: boolean;
}
const getSiteBrandingType = ({
  storeBrandingType,
  brandId,
  isBrandStore,
}: GetSiteBrandingTypeProps): SiteBrandingType => {
  if (storeBrandingType === StoreBrandingType.CustomDTCStore) {
    return SiteBrandingType.CustomDTCStore;
  }
  if (brandId !== BRANDCLUB_BRAND_ID) {
    return SiteBrandingType.CustomStore;
  }
  if (isBrandStore) {
    return SiteBrandingType.BrandStore;
  }
  return SiteBrandingType.UniversalStore;
};

export const useSiteBrandingType = () => {
  const storeBrandingType = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig?.storeBrandingType
  );
  const brandId = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig?.brandId
  );
  const params = useParams();
  const isBrandStore = Boolean(params.brandId || params.sku);

  return getSiteBrandingType({
    storeBrandingType,
    brandId,
    isBrandStore,
  });
};

export const useSiteContext = () => useContext(SiteContext);

export const SiteProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const [previewModeConfig, setPreviewModeConfig] =
    useState<{
      mode: string;
      siteId: string;
      versionId: number;
      entityType: "product" | "brand";
    }>();
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const searchImprovementTreatment = useTreatments(["bc-search-improvement"], {
    email: userProfile?.email || "",
  })["bc-search-improvement"].treatment;
  const splitIO = useSplitClient();
  const syncOptionForRetailerTreatment = splitIO.client?.getTreatment(
    "brandclub-sync-option-for-each-retailer-split",
    { email: userProfile?.email ?? "" }
  );

  const reviewQaTreatment = splitIO.client?.getTreatment(
    "brandclub_dtc_review_qa",
    { email: userProfile?.email ?? "" }
  );

  const mainEntity = useAppSelector(({ mainEntity }) => mainEntity);
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const domainConfigBrandId = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig?.brandId
  );
  const params = useParams();
  const { brandId: brandIdFromRoute, sku: skuFromRoute } = params;
  const [searchParams] = useSearchParams();

  const [persistentQueryParams, setPersistentQueryParams] =
    useState<string>("");

  const [currentBrandId, setCurrentBrandId] = useState<number | undefined>();
  const [site, setSite] = useState<Site>();
  // TODO: factor in query param here
  const siteBrandingType = useSiteBrandingType();

  useEffect(() => {
    try {
      dispatch(loadCategories());
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  // Set preview mode config (use by brand store builder from shopperos portal)
  useEffect(() => {
    const mode = searchParams.get("mode");
    const siteId = searchParams.get("siteId");
    const versionId = parseInt(searchParams.get("versionId") || "0");
    if (mode && siteId && versionId) {
      setPreviewModeConfig({
        mode,
        siteId,
        versionId,
        entityType: skuFromRoute ? "product" : "brand",
      });
    }
  }, [searchParams, brandIdFromRoute, skuFromRoute]);

  // Set current brandId
  useEffect(() => {
    (async () => {
      // if domain is custom, then always set the current brandId to be of the domain
      if (domainConfigBrandId !== BRANDCLUB_BRAND_ID) {
        setCurrentBrandId(domainConfigBrandId);
      } else if (brandIdFromRoute) {
        setCurrentBrandId(parseInt(brandIdFromRoute));
      } else if (skuFromRoute && mainEntity?.id === skuFromRoute) {
        setCurrentBrandId(mainEntity?.entity.brandId);
      } else {
        setCurrentBrandId(undefined);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainConfigBrandId, brandIdFromRoute, skuFromRoute]);

  // Fetch or refresh main entity
  useEffect(() => {
    if (skuFromRoute && mainEntity?.id !== skuFromRoute) {
      dispatch(getMainEntity({ type: "product", id: skuFromRoute }));
    } else if (
      !skuFromRoute &&
      currentBrandId &&
      `${mainEntity?.id}` !== `${currentBrandId}`
    ) {
      dispatch(getMainEntity({ type: "brand", id: `${currentBrandId}` }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBrandId, skuFromRoute]);

  // Fetch or refresh brand entity
  useEffect(() => {
    if (currentBrandId && `${brandEntity?.id}` !== `${currentBrandId}`) {
      dispatch(getBrandEntity({ id: `${currentBrandId}` }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBrandId]);

  useEffect(() => {
    if (
      skuFromRoute &&
      mainEntity &&
      `${mainEntity?.id}` === `${skuFromRoute}` &&
      currentBrandId !== mainEntity?.entity?.brandId
    ) {
      setCurrentBrandId(mainEntity?.entity?.brandId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainEntity, skuFromRoute]);

  // Fetch site
  useEffect(() => {
    if (currentBrandId) {
      if (previewModeConfig && previewModeConfig.mode === "preview") {
        const fetchPageConfig = async () => {
          try {
            const storedSite = await getSite({
              siteId: previewModeConfig.siteId,
              versionId: previewModeConfig.versionId,
            });
            if (previewModeConfig.entityType === "product") {
              const brandSite = await getSiteForLandingPage({
                entityId: `${currentBrandId}`,
                entityType: "brand",
              });
              storedSite.pages = [
                ...(brandSite.data.sitePages.pages.filter(
                  (x) => x.pageType !== "Product"
                ) || []),
                ...(storedSite.pages.filter((x) => x.pageType === "Product") ||
                  []),
              ];
            }
            setSite(storedSite);
            setPersistentQueryParams(
              `mode=${previewModeConfig.mode}&siteId=${previewModeConfig.siteId}&versionId=${previewModeConfig.versionId}`
            );
          } catch (error: any) {
            console.error(`Failed to fetch page config: `, error);
          } finally {
          }
        };
        fetchPageConfig();
      } else if (currentBrandId) {
        const fetchPageConfig = async () => {
          try {
            const res = await getSiteForLandingPage({
              entityId: currentBrandId as any,
              entityType: "brand",
            });
            const data = res.data as SplitSitePagesResponse;
            setSite({ ...data.site, pages: data.sitePages.pages });
          } catch (error: any) {
            console.error(`Failed to fetch page config: `, error);
          } finally {
          }
        };
        fetchPageConfig();
      }
    }
  }, [currentBrandId, previewModeConfig]);

  if (
    siteBrandingType !== SiteBrandingType.UniversalStore &&
    (!brandEntity?.entity?.brandId || !mainEntity?.entity)
  ) {
    return <StoreFullScreenLoading />;
  }
  const routePrefix =
    siteBrandingType === SiteBrandingType.UniversalStore ||
    siteBrandingType === SiteBrandingType.CustomStore
      ? ``
      : `/${escapeUriPath(brandEntity?.entity?.name)}/b/${
          brandEntity?.entity?.brandId
        }`;

  const brandId =
    siteBrandingType === SiteBrandingType.UniversalStore
      ? undefined
      : currentBrandId;

  return (
    <SiteContext.Provider
      value={{
        siteBrandingType,
        site,
        persistentQueryParams,
        previewModeConfig,
        routePrefix,
        brandId,
        abTestingTreatments: {
          searchImprovementTreatment,
          reviewQaTreatment: reviewQaTreatment || "off",
          ...(syncOptionForRetailerTreatment
            ? { syncOptionForRetailerTreatment }
            : undefined),
        },
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export type SiteContextType = {
  siteBrandingType: SiteBrandingType;
  site?: Site;
  persistentQueryParams: string;
  previewModeConfig?: {
    mode: string;
    siteId: string;
    versionId: number;
    entityType: "product" | "brand";
  };
  abTestingTreatments: {
    [key: string]: string;
  };
  routePrefix: string;
  brandId?: number;
};
