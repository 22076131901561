import { isWhite } from "../../../../../utils/StringUtils";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";
import { useResponsiveData } from "../../../../../utils/hooks/useResponsiveData";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import colors from "../../../../styles/colors";
import { LongCardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import PreviewContainer from "../components/PreviewContainer";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import SurveyCard from "./components/SurveyCard";
import { useFetchSurveys } from "./data";
import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";

const SURVEYS_PREVIEW_SIZE = 8;
const SurveysPreview = ({
  brandclubIds,
  backgroundColor = colors.blueWhite,
  arrowColor = "blue",
}: {
  brandclubIds?: string[];
  backgroundColor?: string;
  arrowColor?: "blue" | "grey";
}) => {
  const ref = useRef(null);
  const { linkPrefix } = useLinkPrefix();
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);
  const { data, loading, fetchInit } = useFetchSurveys({
    pageSize: SURVEYS_PREVIEW_SIZE,
    autoInitFetch: false,
    brandclubIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  const responsiveData = useResponsiveData(data);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper ref={ref} backgroundColor={backgroundColor}>
      <PreviewContainer>
        {loading && !data.length ? (
          <StoreLoadingSkeleton
            header
            bgColor={isWhite(backgroundColor) ? "white" : "grey"}
            cardStyle="longCard"
            row={2}
          />
        ) : (
          <>
            <PreviewHeader
              title={"Surveys"}
              linkTo={linkPrefix + "/surveys"}
              subtitle="Earn rewards for completing surveys"
              indicator={arrowColor}
            />
            <LongCardGrid>
              {responsiveData.slice(0, SURVEYS_PREVIEW_SIZE).map((d) => (
                <SurveyCard
                  key={`surveyCard:${d.campaignId}`}
                  survey={d}
                  border={isWhite(backgroundColor)}
                />
              ))}
            </LongCardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default SurveysPreview;
