import LongRewardCard from "@/components/StoreComponents/StoreCard.tsx/LongRewardCard";
import { SurveyRewardCampaignForCarousel } from "@/types/misc";
import {
  getEntityImage,
  CampaignType,
  TABLET_SCREEN_SIZE,
  MOBILE_SCREEN_SIZE,
} from "@brandclub/common-ui";
import { RewardUnlockStrategy } from "@brandclub/types";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../../Pages/components/CardGrid";
import { PreviewHeader } from "../../Pages/components/Header";
import PreviewContainer from "../../Pages/components/PreviewContainer";
import PreviewWidgetWrapper from "../../Pages/components/PreviewWidgetWrapper";
import { useFetchSurveys } from "../../Pages/Survey/data";
import { styled } from "@mui/material/styles";
import { joinClassNames } from "@/utils/StringUtils";
import { useMediaQuery } from "@mui/material";
import { useFallbackImage } from "@/components/StoreComponents/StoreFallbackImage";

interface SurveyCardsPreviewProps {
  brandclubIds?: string[];
  title: string;
  subtitle?: string;
  pageSize?: number;
}
const StyledPreviewContainer = styled(PreviewContainer, {
  label: "SurveyPreviewContainer",
})({
  padding: "30px 0",
});

const StyledCardGrid = styled(CardGrid, {
  label: "SurveyCardsGrid",
})({
  gridTemplateColumns: `repeat(auto-fill, minmax(200px, 226px))`,
  gridGap: 10,
  justifyContent: "start",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    gridTemplateColumns: `repeat(auto-fill, minmax(200px,  226px))`,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gridTemplateColumns: `repeat(auto-fill, minmax(162px,  1fr))`,
  },
});
const StyledPreviewWidgetWrapper = styled(PreviewWidgetWrapper, {
  label: "SurveyPreviewWidgetWrapper",
})({
  paddingLeft: 0,
  paddingRight: 0,
});
const StyledLongRewardCard = styled(LongRewardCard, {
  label: "SurveyLongRewardCard",
})(({ theme }) => ({
  "&&.brand_container": {
    backgroundColor: "#f6f8fa",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    ".img_container": {
      border: "none",
      borderRadius: "0",
      maxWidth: "100%",
      width: "100%",
      padding: 0,
      marginBottom: "21px", //designs show 17px but it's against a text box which has an additional 4px padding
      ".img_icon": {
        margin: "0",
        boxSizing: "border-box",
        objectFit: "cover",
        objectPosition: "center",
        borderRadius: "0",
        width: "100%",
        height: "158px",
      },
    },
  },

  "&&.brand_container.no_cover_img .img_container .img_icon": {
    objectFit: "contain",
    background: theme.palette.common.white,
  },

  "&.useBorder": {
    border: "1px solid #dcdddc",
    ".img_container": {
      border: "none",
    },
    ".details": {
      width: "100%",
      boxSizing: "border-box",
      backgroundColor: "initial",
      border: "none",
      padding: "0px 19px 17px 13px",
      justifyContent: "space-between",
      ".title": {
        fontSize: 14,
        fontWeight: "bold",
      },
      ".subtitle": {
        fontSize: 14,
        lineHeight: 1.29,
      },
      ".reward_info": {
        paddingTop: 14,
      },
    },
  },
}));

const SurveyCard = ({
  survey,
  border = false,
  className,
}: {
  survey: SurveyRewardCampaignForCarousel;
  border?: boolean;
  className?: string;
}) => {
  const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const rewardAmount = survey.extendedAttributes?.reward?.reward?.amount || 0;
  const hasInstantReward =
    survey?.extendedAttributes?.rewardUnlockStrategy ===
    RewardUnlockStrategy.Instant;
  const { imgSrc, onImageLoadError } = useFallbackImage(
    survey?.survey?.previewImageUrl ||
      survey?.brandclub?.imageUrl ||
      getEntityImage(`${survey.brandclub?.brandIds?.[0]}`, "brand"),
    428,
    428
  );

  return (
    <StyledLongRewardCard
      type="brand"
      rewardPillTextOverride={mobileView ? "reward" : undefined}
      title={survey?.survey?.surveyTitle || survey.brandclub?.name || ""}
      subtitle={survey.survey?.description}
      image={imgSrc}
      onImageLoadError={onImageLoadError}
      rewardType={
        hasInstantReward
          ? CampaignType.DailyDealsRewardCampaign
          : CampaignType.SurveyRewardCampaign
      }
      rewardAmount={rewardAmount}
      linkTo={`/survey/${survey.campaignId}`}
      linkState={{
        brandId: survey.brandclub?.brandId,
        brandName: survey.brandclub?.name,
      }}
      border={border}
      className={joinClassNames(
        className,
        survey?.survey?.previewImageUrl ? "cover_img" : "no_cover_img"
      )}
      sx={(theme) => ({
        ".img_container .img_icon": {
          // the optimized preview image may have white spaces on the top and bottom, so we scale the image up to fill the container better
          transform:
            theme.dtc && survey?.survey?.previewImageUrl
              ? "scale(1.2)"
              : undefined,
        },
      })}
    ></StyledLongRewardCard>
  );
};

const SurveyCardsPreview = (props: SurveyCardsPreviewProps) => {
  const { brandclubIds } = props;
  const pageSize = props.pageSize ?? 4;
  const { data, loading } = useFetchSurveys({
    pageSize,
    autoInitFetch: true,
    brandclubIds,
  });

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <StyledPreviewWidgetWrapper>
      <StyledPreviewContainer>
        {loading && !data.length ? (
          <StoreLoadingSkeleton header cardStyle="cardForThree" row={1} />
        ) : (
          <>
            <PreviewHeader
              title={props.title || "Surveys"}
              subtitle={props.subtitle || undefined}
            />
            <StyledCardGrid>
              {data.slice(0, pageSize).map((d) => (
                <SurveyCard
                  key={`surveyCard:${d.campaignId}`}
                  survey={d}
                  border
                />
              ))}
            </StyledCardGrid>
          </>
        )}
      </StyledPreviewContainer>
    </StyledPreviewWidgetWrapper>
  );
};

export default SurveyCardsPreview;
