import { Auth, Hub, Logger } from "aws-amplify";
import {
  BrandclubStoreConfigUtils,
  tryGetVisitorId,
} from "@brandclub/common-ui";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { IAppAuthConfig } from "../redux/types";
import { AuthHelper } from "../components/pages/Auth/AuthHelper";

interface CognitoUserAttributes {
  sub: string;
  birthdate?: string;
  email_verified?: boolean;
  gender?: string;
  name?: string;
  phone_number_verified?: boolean;
  phone_number?: string;
  given_name?: string;
  family_name?: string;
  email: string;
  picture?: string;
}

export interface CognitoUserInfo {
  id: string;
  username: string;
  attributes: CognitoUserAttributes;
}
Logger.LOG_LEVEL = "NONE";

const sharedUserInfoCookieName =
  BrandclubStoreConfigUtils.getAppStage() === "prod"
    ? "bcUserInfo"
    : `bcUserInfo-${BrandclubStoreConfigUtils.getAppStage()}`;

const sharedAuthCookieName =
  BrandclubStoreConfigUtils.getAppStage() === "prod"
    ? "bcSessionToken"
    : `bcSessionToken-${BrandclubStoreConfigUtils.getAppStage()}`;

const sharedRefreshTokenCookieName =
  BrandclubStoreConfigUtils.getAppStage() === "prod"
    ? "bcRefreshToken"
    : `bcRefreshToken-${BrandclubStoreConfigUtils.getAppStage()}`;

const sharedVisitorIdCookieName =
  BrandclubStoreConfigUtils.getAppStage() === "prod"
    ? "bcVisitorId"
    : `bcVisitorId-${BrandclubStoreConfigUtils.getAppStage()}`;

const getAWSCognitoConfig = (authConfig: IAppAuthConfig) => {
  return {
    region: authConfig.region,
    userPoolId: authConfig.userPoolId,
    userPoolWebClientId: authConfig.userPoolClientId,
    oauth: {
      domain: authConfig.signInDomain,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: `${origin}/signinredirect`,
      redirectSignOut: `${origin}/signoutredirect`,
      responseType: "code",
    },
    Auth: {
      userPoolId: authConfig.userPoolId,
      clientId: authConfig.userPoolClientId,
      identityPoolId: authConfig.identityPoolId,
      userPoolWebClientId: authConfig.userPoolClientId,
      ClientId: authConfig.userPoolClientId,
      region: authConfig.region,
    },
  };
};

type AwsCognitoConfig = ReturnType<typeof getAWSCognitoConfig>;

const initializeAmplify = (config: AwsCognitoConfig) => {
  AuthHelper.configure(config);
};

export const initializeAuth = async (authConfig: IAppAuthConfig) => {
  try {
    const cognitoConfig = getAWSCognitoConfig(authConfig);
    const IdentityPoolId = cognitoConfig?.Auth?.identityPoolId;
    if (IdentityPoolId) {
      const cognitoIdentity = new CognitoIdentityClient({
        credentials: fromCognitoIdentityPool({
          identityPoolId: IdentityPoolId,
          clientConfig: { region: authConfig.region },
        }),
      });
      initializeAmplify(cognitoConfig);
      return cognitoIdentity.config.credentials;
    }
    initializeAmplify(cognitoConfig);
  } catch (err) {
    console.error(err);
  }
};

const getAccessToken = async () => {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken();
  return accessToken;
};

const getRefreshToken = async () => {
  const session = await Auth.currentSession();
  const refreshToken = session.getRefreshToken();
  return refreshToken.getToken();
};

type GetUserSignedInStateResponse =
  | {
      signedIn: true;
      userInfo: CognitoUserInfo;
    }
  | {
      signedIn: false;
      userInfo: null;
    };

interface CachedAuth {
  state: GetUserSignedInStateResponse;
  timestamp: number;
}

const AUTH_CACHE_KEY = "_ac_" + btoa("auth_state_v1").replace(/=/g, "");
const CACHE_DURATION = 5 * 60 * 1000;

const getStoredCache = (): CachedAuth | null => {
  try {
    const stored = localStorage.getItem(AUTH_CACHE_KEY);
    if (!stored) return null;

    const cache = JSON.parse(stored) as CachedAuth;
    if (Date.now() - cache.timestamp >= CACHE_DURATION) {
      localStorage.removeItem(AUTH_CACHE_KEY);
      return null;
    }
    return cache;
  } catch (e) {
    localStorage.removeItem(AUTH_CACHE_KEY);
    return null;
  }
};

const setStoredCache = (cache: CachedAuth) => {
  try {
    localStorage.setItem(AUTH_CACHE_KEY, JSON.stringify(cache));
  } catch (e) {
    console.error("Failed to store auth cache");
  }
};

export const clearAuthCache = () => {
  localStorage.removeItem(AUTH_CACHE_KEY);
};

Hub.listen("auth", (data) => {
  const {
    payload: { event },
  } = data;
  if (["signOut", "signIn", "tokenRefresh"].includes(event)) {
    clearAuthCache();
  }
});

export const getUserSignedInState =
  async (): Promise<GetUserSignedInStateResponse> => {
    const storedCache = getStoredCache();
    if (storedCache) {
      return storedCache.state;
    }

    try {
      const session = await Auth.currentSession();
      if (!session || !session.isValid()) {
        const newCache = {
          state: {
            signedIn: false,
            userInfo: null,
          } as GetUserSignedInStateResponse,
          timestamp: Date.now(),
        };
        setStoredCache(newCache);
        return newCache.state;
      }

      const userInfo: CognitoUserInfo | undefined =
        await Auth.currentUserInfo();
      if (!userInfo || !userInfo.username) {
        const newCache = {
          state: {
            signedIn: false,
            userInfo: null,
          } as GetUserSignedInStateResponse,
          timestamp: Date.now(),
        };
        setStoredCache(newCache);
        return newCache.state;
      }

      const newCache = {
        state: { signedIn: true, userInfo } as GetUserSignedInStateResponse,
        timestamp: Date.now(),
      };
      setStoredCache(newCache);
      return newCache.state;
    } catch (e) {
      const newCache = {
        state: {
          signedIn: false,
          userInfo: null,
        } as GetUserSignedInStateResponse,
        timestamp: Date.now(),
      };
      setStoredCache(newCache);
      return newCache.state;
    }
  };

export const signOut = async () => {
  try {
    localStorage.removeItem(sharedUserInfoCookieName);
    await Auth.signOut();
  } catch (e) {
    console.error(e);
  }
};

export const getJwt = async () => {
  try {
    const accessToken = await getAccessToken();
    return accessToken.getJwtToken();
  } catch (e) {
    if (e !== "No current user") {
      console.error(e);
    }
    return "";
  }
};

export const getVisitorIdHeader = async () => {
  const uniqueVisitorId = await tryGetVisitorId();
  const uniqueVisitorIdFromCookie = getVisitorIdCookie();
  const uniqueVisitorIdToUse =
    uniqueVisitorIdFromCookie && uniqueVisitorIdFromCookie !== ""
      ? uniqueVisitorIdFromCookie
      : uniqueVisitorId;
  const headers: any = {};
  if (uniqueVisitorIdToUse) {
    headers["x-bc-visitor-id"] = uniqueVisitorIdToUse;
  }
  return headers;
};

export const getAuthorizationHeader = async () => {
  const uniqueVisitorId = await tryGetVisitorId();
  const uniqueVisitorIdFromCookie = getVisitorIdCookie();
  const uniqueVisitorIdToUse =
    uniqueVisitorIdFromCookie && uniqueVisitorIdFromCookie !== ""
      ? uniqueVisitorIdFromCookie
      : uniqueVisitorId;
  const jwt = await getJwt();
  const headers: any = {};
  if (jwt) {
    headers["Authorization"] = `Bearer ${jwt}`;
  }
  if (uniqueVisitorIdToUse) {
    headers["x-bc-visitor-id"] = uniqueVisitorIdToUse;
  }
  return headers;
};

export const setOtpAuth = async (userInfo: any) => {
  userInfo = {
    ...userInfo,
    ...(userInfo.cognitoUserAttributes || {}),
    username: userInfo.username || userInfo.userId,
  };
  localStorage.setItem(sharedUserInfoCookieName, JSON.stringify(userInfo));
  Hub.dispatch("auth", {
    event: "signIn_Complete",
    data: { username: userInfo.username },
  });
};

export const setSharedAuthCookie = async () => {
  const accessToken = await getAccessToken();
  const refreshToken = await getRefreshToken();
  // get top level domain
  const topLevelDomain = window.location.hostname
    .split(".")
    .slice(-2)
    .join(".");
  const jwt = accessToken.getJwtToken();
  const expires = accessToken.getExpiration(); // epoch time in seconds
  if (jwt && jwt !== "") {
    const existingCookie = getSharedAuthCookie();
    if (existingCookie) {
      clearSharedAuthCookie();
    }
    const date = new Date(expires * 1000);
    document.cookie = `${sharedAuthCookieName}=${jwt}; path=/; domain=.${topLevelDomain}; expires=${date.toUTCString()}; SameSite=None; Secure`;
  }
  if (refreshToken && refreshToken !== "") {
    // expires in 30 days
    const date = new Date();
    const clientId = await AuthHelper.getClientIdFromSession();
    const cookieValue = `${clientId}|${refreshToken}`;
    date.setDate(date.getDate() + 30);
    const expires = date.toUTCString();
    document.cookie = `${sharedRefreshTokenCookieName}=${cookieValue}; path=/; domain=.${topLevelDomain}; expires=${expires}; SameSite=None; Secure`;
  }
};

export const clearSharedAuthCookie = () => {
  // get top level domain
  const topLevelDomain = window.location.hostname
    .split(".")
    .slice(-2)
    .join(".");
  document.cookie = `${sharedAuthCookieName}=; path=/; domain=.${topLevelDomain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure`;
  document.cookie = `${sharedRefreshTokenCookieName}=; path=/; domain=.${topLevelDomain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure`;
};

export const getSharedAuthCookie = () => {
  const name = `${sharedAuthCookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getVisitorIdCookie = () => {
  const name = `${sharedVisitorIdCookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
