import { AppDispatch, ThunkExtraArguments } from "../store";
import { TopBrand } from "../types";
import { getRewardAmountsByBrand } from "../../api/graphQl/authenticated";
import { setTopBrandLoading, setTopBrands } from "../reducers/topBrands";

/* --------------------- topBrands ---------------------*/

export const loadTopBrands =
  (variables?: Record<string, any>) =>
  async (
    dispatch: AppDispatch,
    _getState: unknown,
    { apolloClient }: ThunkExtraArguments
  ): Promise<TopBrand[] | null> => {
    try {
      dispatch(setTopBrandLoading());
      const { data } = await apolloClient.query({
        query: getRewardAmountsByBrand,
        variables,
        fetchPolicy: "network-only",
      });
      if (data) {
        const topBrands = data.UsersBrandClubs.filter(
          (d: { brandClub: TopBrand }) => d.brandClub
        ).map((d: { brandClub: TopBrand }) => d.brandClub);
        dispatch(setTopBrands(topBrands));
        return topBrands;
      }
    } catch (e) {
      console.error("loadTopBrands error: ", e);
    }
    return null;
  };
