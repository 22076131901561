import { useEffect, useContext } from "react";

import { createContext, RefObject } from "react";

export const StoreSinglePageWrapperContext =
  createContext<{
    wrapper: RefObject<HTMLDivElement | null>;
  } | null>(null);

export const useScrollSinglePageWrapperToTop = <T,>(trigger: T) => {
  const wrapperContext = useContext(StoreSinglePageWrapperContext);
  if (!wrapperContext) {
    throw new Error("this hook must be used inside StoreSinglePageOutlet");
  }
  useEffect(() => {
    wrapperContext.wrapper.current?.scrollTo(0, 0);
  }, [trigger, wrapperContext.wrapper]);
};

/**
 * Hook that ensures the scrollbar is always visible in the single page wrapper
 * to prevent layout shifts that occur when content changes cause the scrollbar
 * to appear or disappear.
 *
 * @param condition - Boolean flag that controls whether the effect should be applied.
 */
export const useAlwaysVisibleSinglePageScrollbar = (condition: boolean) => {
  const wrapperContext = useContext(StoreSinglePageWrapperContext);
  useEffect(() => {
    const wrapper = wrapperContext?.wrapper?.current;
    if (!wrapper || !condition) {
      return;
    }
    wrapper.style.overflowY = "scroll";
    return () => {
      wrapper.style.overflowY = "auto";
    };
  }, [wrapperContext, condition]);
};
